// GraphQL re-usable fragments to avoid repeating fields in several queries
export const FullNarrativeSectionFragment = `
    fragment FullNarrativeSectionFragment on NarrativeSection {
        id
        title
        items {
            id
            position
            type
            value
            showColumns
            address
        }
        kpis {
            id
            position
            title
            value
        }
        visible
    }`

export const FullNarrativeFragment = `
    ${FullNarrativeSectionFragment}
    fragment FullNarrativeFragment on Narrative {
        chiefUnderwriter
        dealName
        dealType
        id
        loanAmount
        msa
        projectId
        projectImage
        propertyAddress
        propertyName
        propertyType
        dealOverviewSections {
            ...FullNarrativeSectionFragment
        }
        waiverSections {
            ...FullNarrativeSectionFragment
        }
        strengthSections {
            ...FullNarrativeSectionFragment
        }
        participantSections {
            ...FullNarrativeSectionFragment
        }
        propertySections {
            ...FullNarrativeSectionFragment
        }
        affordabilitySections {
            ...FullNarrativeSectionFragment
        }
        managementSections {
            ...FullNarrativeSectionFragment
        }
        marketSections {
            ...FullNarrativeSectionFragment
        }
        underwritingSections {
            ...FullNarrativeSectionFragment
        }
        mapSections {
            ...FullNarrativeSectionFragment
        }
    }`
