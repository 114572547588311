import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { NarrativeTableType } from '../../../../store/types/dealnarrative'

type Props = {
    table: any
    showColumsSelection?: boolean
    columns?: string
    onChange?: Function
}

export default function LeaseUPTable(props: Props) {
    const { table, showColumsSelection, columns, onChange } = props
    //console.log('table', table)
    const rows = table.data
    const [pageURL, setPageURL] = useState('')
    useEffect(() => {
        setPageURL(window.location.href)
    }, [])

    if (!rows || rows.length === 0) return

    const columnsCheckedArray = useMemo(() => {
        const columnsCheckedArray = new Array(rows[0].length)
        if (!columns) columnsCheckedArray.fill(true)
        else {
            columnsCheckedArray.fill(false)
            columns.split(',').map((item, idx) => {
                columnsCheckedArray[+item] = true
            })
        }
        return columnsCheckedArray
    }, [columns])

    const [checkedState, setCheckedState] = useState(columnsCheckedArray)

    const onchange = useCallback(
        (idx) => {
            //console.log('onchange', idx)
            const updatedCheckedState = checkedState.map((item, index) => (index === idx ? !item : item))

            setCheckedState(updatedCheckedState)
            let updatedCheckedStateStr = ''
            updatedCheckedState.map((item, index) => {
                if (item) {
                    if (updatedCheckedStateStr == '') updatedCheckedStateStr += index
                    else updatedCheckedStateStr += ',' + index
                }
            })
            onChange(updatedCheckedStateStr)
            //console.log('checked', updatedCheckedState, updatedCheckedStateStr)
        },
        [checkedState, columnsCheckedArray],
    )

    const shortCells = useMemo(() => {
        const shortCells = new Array(rows[1].length).fill(false)
        if (pageURL?.indexOf('report?id') > -1) {
            for (let i = 0; i < rows[1].length; i++) {
                const cell = rows[1][i]
                if (
                    [
                        'Stabilization Period',
                        'Net Move-Ins - Actual',
                        'Net Move-Ins - Projected',
                        'Move Outs',
                        'Total Projected Weekly Move-Ins',
                        'Projected Cumulative Net Move-Ins',
                        "New CO's Delivered",
                        "Total Units CO'd",
                    ].includes(cell)
                )
                    shortCells[i] = true
            }
        }
        return shortCells
    }, [rows, pageURL])
    // Some rows are highlighted, check the label of the first column
    rows.forEach((row) => {
        const firstCell = row.length > 0 ? row[0] : ''

        if (
            firstCell === 'Recommended Loan Amount' ||
            firstCell === 'DSCR (Actual)' ||
            firstCell === 'DSCR (Floor)' ||
            firstCell === 'LTV'
        )
            row.highlighted = true
    })

    if (table.header) {
        for (let i = rows[0].length - table.header.length; i > 0; i--) {
            table.header.push('')
        }
    }

    if (table.header2) {
        for (let i = rows[0].length - table.header2.length; i > 0; i--) {
            table.header2.push('')
        }
    }

    const useBoldNameCell = [
        NarrativeTableType.REFINANCE_INFORMATION,
        NarrativeTableType.PROPERTY_DETAILS,
        NarrativeTableType.COST_BASIS,
    ].includes(table.type)

    const tableCss: any = useMemo(() => {
        return pageURL?.indexOf('report?id') > -1
            ? {
                  margin: '0 auto',
                  maxHeight: '800px',
                  width: '799px',
                  maxWidth: '700px',
                  fontSize: '11px',
                  height: '800px',
                  lineHeight: '15px',
                  transform: 'rotate(90deg)',
                  left: '-100px',
                  position: 'relative',
                  top: '20px',
              }
            : {}
    }, [pageURL])
    // console.log('tableCss', tableCss)
    return (
        <>
            <table className={'LeaseUpTable baseTable'} style={tableCss}>
                <thead>
                    {showColumsSelection && (
                        <tr>
                            {rows[0].map((cell, colIndex) => (
                                <th key={colIndex} className={colIndex === 0 ? 'text-left' : 'text-right'}>
                                    {colIndex > 0 && (
                                        <div>
                                            <div>show</div>
                                            <div>
                                                <input
                                                    type={'checkbox'}
                                                    style={{ verticalAlign: 'middle', marginRight: '10px' }}
                                                    checked={checkedState[colIndex]}
                                                    onChange={() => onchange(colIndex)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    )}
                    <tr>
                        {table?.header?.map((cell, colIndex) => {
                            return (
                                <th
                                    key={colIndex}
                                    className={
                                        'leaseUpCell ' +
                                        (colIndex === 0 ? 'text-left' : 'text-right') +
                                        (shortCells[colIndex] ? ' shortCell' : '')
                                    }
                                >
                                    {cell}
                                </th>
                            )
                        })}
                    </tr>
                    {table?.header2 && (
                        <tr>
                            {table?.header2.map((cell, colIndex) => {
                                return (
                                    <th
                                        key={colIndex}
                                        className={
                                            'leaseUpCell ' +
                                            (colIndex === 0 ? 'text-left' : 'text-right') +
                                            (shortCells[colIndex] ? ' shortCell' : '')
                                        }
                                    >
                                        {showColumsSelection || checkedState[colIndex] ? cell : ''}
                                    </th>
                                )
                            })}
                        </tr>
                    )}
                    <tr>
                        {rows[0].map((cell, colIndex) => {
                            return (
                                <th
                                    key={colIndex}
                                    className={
                                        'leaseUpCell ' +
                                        (colIndex === 0 ? 'text-left' : 'text-right') +
                                        (shortCells[colIndex] ? ' shortCell' : '')
                                    }
                                >
                                    {showColumsSelection || checkedState[colIndex] ? cell : ''}
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {rows.slice(1).map((row, rowIndex) => {
                        let color = ''
                        const comment = row[row.length - 1]
                        if (comment.indexOf('Current Week') > -1) color = 'orange'
                        if (comment.indexOf('Rate Lock') > -1) color = 'red'
                        if (comment.indexOf('Stabilization') > -1) color = 'lightGreen'

                        const cssstyle = color != '' ? { backgroundColor: color } : {}
                        return (
                            <tr key={rowIndex} className={row.highlighted ? 'highlighted' : ''}>
                                {row.map((cell, colIndex) => {
                                    return (
                                        <td
                                            key={colIndex}
                                            className={
                                                'leaseUpCell ' +
                                                (colIndex === 0 ? 'text-left' : 'text-right') +
                                                (useBoldNameCell &&
                                                colIndex % 2 == 0 &&
                                                cell?.toString().toLowerCase().indexOf('per unit') == -1
                                                    ? ' highlighted'
                                                    : '') +
                                                (shortCells[colIndex] ? ' shortCell' : '')
                                            }
                                            style={cssstyle}
                                        >
                                            {showColumsSelection || checkedState[colIndex] ? cell : ''}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {/*language=scss*/}
            <style jsx>{`
                .leaseUpCell {
                    font-size: 11px !important;
                }
                :global(.modal-content) {
                    width: 98% !important;
                }
                .shortCell {
                    max-width: 40px;
                    min-width: 40px;
                }
            `}</style>
        </>
    )
}
