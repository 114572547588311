import React from 'react'
import { FieldRenderProps } from 'react-final-form'

// @ts-ignore
export default function SelectAdapter({ input, meta, className, children, ...rest }: FieldRenderProps<any>) {
    const hasError = meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)
    return (
        <React.Fragment>
            <select {...input} {...rest} className={`${className} ${hasError ? 'error' : ''}`} data-testid={input.name}>
                {children}
            </select>
            {hasError && (
                <span className={'errorText'}>
                    {meta.error}
                    {meta.submitError}
                </span>
            )}
            {/*language=SCSS*/}
            <style jsx>{`
                .errorText {
                    color: #cf6679;
                    position: absolute;
                    font-size: 11px;
                }
                :global(.form-control option) {
                    background-color: white;
                    line-height: 18px;
                    font-size: 13px;
                }
            `}</style>
        </React.Fragment>
    )
}
