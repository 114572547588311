import React from 'react'
import RecommendedLoanOptionsTable from '../tables/RecommendedLoanOptionsTable'
import RateSummaryTable from '../tables/RateSummaryTable'
import AffordabilityEstimatorTable from '../tables/AffordabilityEstimatorTable'
import UnitMixTable from '../tables/UnitMixTable'
import CollectionsTable from '../tables/CollectionsTable'
import { NarrativeTableType } from '../../../../store/types/dealnarrative'
import ReisDataTable from '../tables/ReisDataTable'
import MarketUnitMixRentDetailsTable from '../tables/MarketUnitMixRentDetailsTable'
import CollectionsYearsTable from '../tables/CollectionsYearsTable'
import MostRecentMonthofCollectionsSmallTable from '../tables/MostRecentMonthofCollectionsSmallTable'
import MostRecentMonthofCollectionsTable from '../tables/MostRecentMonthofCollectionsTable'
import MarketUnitMixRentDetailsTable2 from '../tables/MarketUnitMixRentDetailsTable2'
import LeaseUPTable from '../tables/LeaseUPTable'

type Props = {
    table: any
    showColumsSelection?: boolean
    columns?: string
    onChange?: Function
}

export default function TableFormatter(props: Props) {
    const { table, showColumsSelection, columns, onChange } = props
    if (!table) return null

    let Formatter = RecommendedLoanOptionsTable

    if (table?.type === NarrativeTableType.FIXED_RATE_SUMMARY) Formatter = RateSummaryTable
    if (table?.type === NarrativeTableType.ADJUSTABLE_RATE_SUMMARY) Formatter = RateSummaryTable
    if (table?.type === NarrativeTableType.SUPPLEMENTAL_RATE_SUMMARY) Formatter = RateSummaryTable
    else if (table?.type === NarrativeTableType.AFFORDABILITY_ESTIMATOR) Formatter = AffordabilityEstimatorTable
    else if (table?.type === NarrativeTableType.UNIT_MIX || table?.type === NarrativeTableType.RENT_ANALYSIS)
        Formatter = UnitMixTable
    else if (table?.type === NarrativeTableType.COLLECTIONS) Formatter = CollectionsTable
    else if (table?.type === NarrativeTableType.COLLECTIONS_YEARS) Formatter = CollectionsYearsTable
    else if (table?.type === NarrativeTableType.REIS_DATA_INPUT) Formatter = ReisDataTable
    else if (table?.type === NarrativeTableType.MARKET_UNIT_MIX_RENT_DETAILS) Formatter = MarketUnitMixRentDetailsTable
    else if (table?.type === NarrativeTableType.MARKET_UNIT_MIX_RENT_DETAILS2)
        Formatter = MarketUnitMixRentDetailsTable2
    else if (table?.type === NarrativeTableType.MOST_RECENT_MONTH_OF_COLLECTIONS)
        Formatter = MostRecentMonthofCollectionsTable
    else if (table?.type === NarrativeTableType.MOST_RECENT_MONTH_OF_COLLECTIONS_SMOOTH)
        Formatter = MostRecentMonthofCollectionsSmallTable
    else if (table?.type === NarrativeTableType.LEASE_UP) Formatter = LeaseUPTable

    if (Formatter)
        return (
            <Formatter
                table={table}
                showColumsSelection={showColumsSelection || false}
                columns={columns}
                onChange={onChange}
            />
        )
    else return null
}
