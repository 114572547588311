import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import InputAdapter from '../../../controls/inputs/dealnarrative/InputAdapter'
import { Field } from 'react-final-form'
import { NarrativeSectionItemType } from '../../../../store/types/dealnarrative'
import NarrativeFormSectionButtons from './NarrativeFormSectionButtons'
import NarrativeFormField from './NarrativeFormField'
import NarrativeFormKpi from './NarrativeFormKpi'
import NarrativeFormTable from './NarrativeFormTable'
import ImageUploadAdapter from '../../../controls/inputs/dealnarrative/ImageUploadAdapter'
import useAvailableTables from '../tables/useAvailableTables'
import { TinyMceAdapter } from '../../../controls/inputs/dealnarrative/TinyMceAdapter'
import { Col, Row } from 'react-bootstrap'
import NarrativeFormSwitch from './NarrativeFormSwitch'
import Collapse from 'react-collapse'
import NarrativeFormSectionItem from './NarrativeFormSectionItem'

const FIELD_MAX_LENGTH = 255

type Props = {
    sectionName: string
}

export default function NarrativeFormCustomSectionList(props: Props) {
    const { sectionName } = props
    const { loading: isTablesLoading, error: tablesError, tables: availableTables } = useAvailableTables()

    if (isTablesLoading) {
        return <>Loading available tables...</>
    } else if (tablesError) {
        return <>{tablesError}</>
    }

    return (
        <div className="NarrativeFormCustomSectionList">
            <FieldArray name={sectionName}>
                {({ fields: sectionFields }) =>
                    sectionFields.map((sectionProp, sectionIndex) => {
                        // By default, section is visible and expanded
                        const isSectionVisible =
                            sectionFields.value[sectionIndex].visible === undefined
                                ? true
                                : sectionFields.value[sectionIndex].visible
                        return (
                            <div key={sectionProp} className={'NarrativeFormCustomSection'}>
                                <Row>
                                    <Col>
                                        <NarrativeFormField htmlFor={`${sectionProp}.title`} label={'Title'} fullWidth>
                                            <Field
                                                id={`${sectionProp}.title`}
                                                component={InputAdapter}
                                                name={`${sectionProp}.title`}
                                                initialValue={sectionFields.value[sectionIndex].title || ''}
                                                className="form-control"
                                                maxLength={FIELD_MAX_LENGTH}
                                            />
                                        </NarrativeFormField>
                                    </Col>
                                    <Col xs={'auto'} className={'visibleCol'}>
                                        <div className={'col-form-label'}>
                                            <NarrativeFormSwitch
                                                name={`${sectionProp}.visible`}
                                                initialValue={isSectionVisible}
                                            >
                                                Visible
                                            </NarrativeFormSwitch>
                                        </div>
                                    </Col>
                                </Row>
                                <Collapse isOpened={isSectionVisible}>
                                    <div className={'collapsibleAreaContent'}>
                                        {sectionIndex === 0 && sectionFields.value[sectionIndex].showKpis && (
                                            <>
                                                <div>KPIs</div>
                                                <FieldArray name={`${sectionProp}.kpis`}>
                                                    {({ fields: kpiFields }) => (
                                                        <>
                                                            {kpiFields.map((kpiProp, kpiIndex) => (
                                                                <NarrativeFormSectionItem
                                                                    key={kpiProp}
                                                                    className={'kpiSectionItem'}
                                                                    onDelete={() => kpiFields.remove(kpiIndex)}
                                                                >
                                                                    <NarrativeFormKpi
                                                                        name={kpiProp}
                                                                        title={kpiFields.value[kpiIndex].title}
                                                                        value={kpiFields.value[kpiIndex].value}
                                                                    />
                                                                </NarrativeFormSectionItem>
                                                            ))}
                                                            <div>
                                                                <a
                                                                    className={'addKpi'}
                                                                    href={'#'}
                                                                    onClick={() => kpiFields.push({})}
                                                                >
                                                                    + Add KPI
                                                                </a>
                                                            </div>
                                                            <hr className={'end-of-section'} />
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </>
                                        )}

                                        <FieldArray name={`${sectionProp}.items`}>
                                            {({ fields: itemFields }) => (
                                                <>
                                                    {itemFields.map((itemProp, itemIndex) => (
                                                        <React.Fragment key={itemProp}>
                                                            {itemFields.value[itemIndex].type ===
                                                                NarrativeSectionItemType.BLURB && (
                                                                <NarrativeFormSectionItem
                                                                    className={'blurbSectionItem'}
                                                                    onDelete={() => itemFields.remove(itemIndex)}
                                                                >
                                                                    <NarrativeFormField
                                                                        fullWidth
                                                                        htmlFor={`${itemProp}.value`}
                                                                        label={'Blurb'}
                                                                    >
                                                                        <Field
                                                                            id={`${itemProp}.value`}
                                                                            component={TinyMceAdapter}
                                                                            name={`${itemProp}.value`}
                                                                            initialValue={
                                                                                itemFields.value[itemIndex].value || ''
                                                                            }
                                                                            className="form-control"
                                                                            placeholder={'content'}
                                                                        />
                                                                    </NarrativeFormField>
                                                                </NarrativeFormSectionItem>
                                                            )}
                                                            {itemFields.value[itemIndex].value &&
                                                                itemFields.value[itemIndex].value != '' &&
                                                                itemFields.value[itemIndex].type ===
                                                                    NarrativeSectionItemType.TABLE && (
                                                                    <NarrativeFormSectionItem
                                                                        className={'tableSectionItem'}
                                                                        onDelete={() => itemFields.remove(itemIndex)}
                                                                    >
                                                                        {/*
                                                                        console.log(
                                                                            itemIndex,
                                                                            itemFields.value[itemIndex],
                                                                            availableTables,
                                                                        )
                                                                        */}
                                                                        <NarrativeFormTable
                                                                            table={(itemFields.value[itemIndex]
                                                                                .address &&
                                                                            itemFields.value[itemIndex].address != ''
                                                                                ? availableTables?.find(
                                                                                      (file) =>
                                                                                          file.address ===
                                                                                          itemFields.value[itemIndex]
                                                                                              .address,
                                                                                  )
                                                                                : availableTables[0]
                                                                            ).tables.find(
                                                                                (table) =>
                                                                                    table.type ===
                                                                                    itemFields.value[itemIndex].value,
                                                                            )}
                                                                            columns={
                                                                                itemFields.value[itemIndex].showColumns
                                                                            }
                                                                            onChange={(cols: string) => {
                                                                                itemFields.value[
                                                                                    itemIndex
                                                                                ].showColumns = cols
                                                                            }}
                                                                        />
                                                                    </NarrativeFormSectionItem>
                                                                )}
                                                            {itemFields.value[itemIndex].type ===
                                                                NarrativeSectionItemType.IMAGE && (
                                                                <NarrativeFormSectionItem
                                                                    className={'imageSectionItem'}
                                                                    onDelete={() => itemFields.remove(itemIndex)}
                                                                >
                                                                    <Field
                                                                        id={`${itemProp}.value`}
                                                                        component={ImageUploadAdapter}
                                                                        name={`${itemProp}.value`}
                                                                        initialValue={
                                                                            itemFields.value[itemIndex].value || ''
                                                                        }
                                                                        className={'large-image'}
                                                                    />
                                                                </NarrativeFormSectionItem>
                                                            )}
                                                            {itemFields.value[itemIndex].type ===
                                                                NarrativeSectionItemType.GALLERY &&
                                                                itemFields.value[itemIndex].value?.length > 0 && (
                                                                    <NarrativeFormSectionItem
                                                                        className={'gallerySectionItem'}
                                                                        onDelete={() => itemFields.remove(itemIndex)}
                                                                    >
                                                                        <FieldArray name={`${itemProp}.value`}>
                                                                            {({ fields: imageFields }) => (
                                                                                <div
                                                                                    className={
                                                                                        'row no-gutters image-gallery-row'
                                                                                    }
                                                                                >
                                                                                    {imageFields.map(
                                                                                        (imageProp, imageIndex) => (
                                                                                            <div key={imageProp}>
                                                                                                <Field
                                                                                                    id={`${imageProp}`}
                                                                                                    component={
                                                                                                        ImageUploadAdapter
                                                                                                    }
                                                                                                    name={`${imageProp}`}
                                                                                                    initialValue={
                                                                                                        imageFields
                                                                                                            .value[
                                                                                                            imageIndex
                                                                                                        ] || ''
                                                                                                    }
                                                                                                    className={
                                                                                                        'small-image'
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        ),
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </FieldArray>
                                                                    </NarrativeFormSectionItem>
                                                                )}
                                                        </React.Fragment>
                                                    ))}
                                                    <NarrativeFormSectionButtons
                                                        onAddItem={(newItem) => itemFields.push(newItem)}
                                                        onAddSection={() => {
                                                            // @ts-ignore
                                                            sectionFields.insertAt(sectionIndex + 1, {})
                                                        }}
                                                        onDeleteSection={
                                                            sectionFields.value[sectionIndex].isMandatory
                                                                ? undefined
                                                                : () => sectionFields.remove(sectionIndex)
                                                        }
                                                        tables={availableTables}
                                                    />
                                                </>
                                            )}
                                        </FieldArray>
                                    </div>
                                </Collapse>
                                <hr className={'endOfSection'} />
                            </div>
                        )
                    })
                }
            </FieldArray>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    .addKpi {
                        color: #007bff !important;
                    }
                `}
            </style>
        </div>
    )
}
