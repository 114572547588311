import OpeningForm from '../forms/OpeningForm'
import { STEP_ID } from '../../../../utils/dealnarrative/constants/narrativeStepIds'
import OverviewForm from '../forms/OverviewForm'
import ParticipantForm from '../forms/ParticipantForm'
import PropertyForm from '../forms/PropertyForm'
import MarketForm from '../forms/MarketForm'
import UnderwritingForm from '../forms/UnderwritingForm'
import MapsForm from '../forms/MapsForm'

export const steps = [
    {
        name: 'Deal Information',
        id: STEP_ID.DEAL_INFORMATION,
        component: OpeningForm,
    },
    {
        name: 'Deal Overview',
        id: STEP_ID.DEAL_OVERVIEW,
        component: OverviewForm,
    },
    {
        name: 'Participants Overview',
        id: STEP_ID.PARTICIPANTS_OVERVIEW,
        component: ParticipantForm,
    },
    {
        name: 'Property Overview',
        id: STEP_ID.PROPERTY,
        component: PropertyForm,
    },
    {
        name: 'Market Analysis',
        id: STEP_ID.MARKET_ANALYSIS,
        component: MarketForm,
    },
    {
        name: 'Underwriting Assumptions',
        id: STEP_ID.UNDERWRITING_ASSUMPTIONS,
        component: UnderwritingForm,
    },
    {
        name: 'Maps & Photos',
        id: STEP_ID.MAPS_PHOTOS,
        component: MapsForm,
    },
]
