import React, { useCallback, useRef, useState } from 'react'
import { NarrativeSectionItemType } from '../../../../store/types/dealnarrative'
import SelectTable from './SelectTable'
import NarrativeModal from '../modals/NarrativeModal'
import { Dropdown } from 'react-bootstrap'
import _ from 'lodash'
import BlurbIcon from '../../../../theme/dealnarrative/icons/Blurb.png'
import TableIcon from '../../../../theme/dealnarrative/icons/View.png'
import ImageIcon from '../../../../theme/dealnarrative/icons/Edit.png'

type Props = {
    onAddItem: Function
    onAddSection: Function
    onDeleteSection?: Function
    tables: any
}

export default function NarrativeFormSectionButtons(props: Props) {
    const { onAddItem, onAddSection, onDeleteSection, tables } = props
    const [showSelectTable, setShowSelectTable] = useState(false)

    // Generate a unique identifier
    const idRef = useRef(_.uniqueId())
    const buttonId = idRef.current

    const onClickAddBlurb = useCallback(
        () =>
            onAddItem({
                type: NarrativeSectionItemType.BLURB,
                value: '',
            }),
        [onAddItem],
    )

    const onClickAddImage = useCallback(() => {
        onAddItem({
            type: NarrativeSectionItemType.IMAGE,
            value: '',
        })
    }, [onAddItem])

    const onClickAddGallery = useCallback(() => {
        onAddItem({
            type: NarrativeSectionItemType.GALLERY,
            value: ['', '', '', ''],
        })
    }, [onAddItem])

    const onClickAddTable = useCallback(() => {
        setShowSelectTable(true)
    }, [])

    const onClickAddSection = useCallback(() => onAddSection(), [onAddSection])

    const onClickDeleteSection = useCallback(() => onDeleteSection(), [onDeleteSection])

    const onSelectTable = useCallback(
        (table) => {
            setShowSelectTable(false)
            onAddItem({
                type: NarrativeSectionItemType.TABLE,
                value: table.type,
                address: table.address,
            })
        },
        [onAddItem],
    )

    return (
        <div className="NarrativeFormSectionButtons">
            <NarrativeModal
                show={showSelectTable}
                title={'Choose a table'}
                size={'xl'}
                onAbort={() => setShowSelectTable(false)}
            >
                <SelectTable onSelectTable={onSelectTable} tables={tables} />
            </NarrativeModal>
            <div className="d-flex justify-content-between buttonContainer">
                <div style={{ width: '70px' }}>
                    <Dropdown className={'dnAddDropDown'}>
                        <Dropdown.Toggle id={buttonId} variant={'outline-primary'}>
                            <span style={{ position: 'relative', left: '-5px' }}>Add</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item className="dropdownItem" href="#" onClick={() => onClickAddImage()}>
                                <img src={ImageIcon} alt={'Image'} />
                                Image
                            </Dropdown.Item>
                            <Dropdown.Item className="dropdownItem" href="#" onClick={() => onClickAddGallery()}>
                                <img src={ImageIcon} alt={'Image Gallery'} />
                                Image Gallery
                            </Dropdown.Item>
                            <Dropdown.Item className="dropdownItem" href="#" onClick={() => onClickAddTable()}>
                                <img src={TableIcon} alt={'Table'} />
                                Table
                            </Dropdown.Item>
                            <Dropdown.Item className="dropdownItem" href="#" onClick={() => onClickAddBlurb()}>
                                <img src={BlurbIcon} alt={'Blurb'} />
                                Blurb
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {onDeleteSection && (
                    <div className={'ml-auto'}>
                        <button
                            type="button"
                            className={'btn btn-outline-primary'}
                            onClick={() => onClickDeleteSection()}
                        >
                            - Delete Section
                        </button>
                    </div>
                )}
                <div className={''}>
                    <button type="button" className={'btn btn-outline-primary'} onClick={() => onClickAddSection()}>
                        + Add Section
                    </button>
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    .dnAddDropDown {
                        width: 80px;
                    }
                `}
            </style>
        </div>
    )
}
