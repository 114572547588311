import { gql, useMutation } from '@apollo/client'

export default function useMutationUploadNarrativeFile() {
    // Make sure every sub-entity includes the prop "id" and all updated fields, to allow entities at all levels to be updated in the Apollo cache
    return useMutation(
        gql`
            mutation uploadNarrativeFile($projectId: Int!, $file: Upload!) {
                uploadNarrativeFile(projectId: $projectId, file: $file)
            }
        `,
    )
}
