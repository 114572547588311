import React from 'react'
import TableFormatter from './TableFormatter'

type Props = {
    table: any
    onSelectTable: Function
}

export default function SelectableTable(props: Props) {
    const { table, onSelectTable } = props

    if (!table?.data) return null

    return (
        <div className={'SelectableTable d-flex flex-column align-items-center'} onClick={() => onSelectTable(table)}>
            <h2>{table.title}</h2>
            <div className={'tableRow'}>
                <TableFormatter table={table} />
            </div>
        </div>
    )
}
