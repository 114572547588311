import React from 'react'
import DeleteIcon from '../../../../theme/dealnarrative/icons/delete/m.png'

type Props = {
    className?: string
    onDelete?: Function
    children?: React.ReactNode
}

export default function NarrativeFormSectionItem(props: Props) {
    const { className, onDelete, children = null } = props

    return (
        <div className={`NarrativeFormSectionItem ${className || ''}`}>
            <div className={'contentCol'}>{children || null}</div>
            {onDelete && (
                <div className={'deleteButtonCol'}>
                    <div className={'deleteIcon'}>
                        <img src={DeleteIcon} alt={'Delete'} onClick={() => onDelete()} />
                    </div>
                </div>
            )}
        </div>
    )
}
