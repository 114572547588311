import { gql, useQuery } from '@apollo/client'
import { FullNarrativeFragment } from '../../fragments/dealnarrative/fragments'

export default function useQueryNarrativeById(id: number, skip = false, projectId: number) {
    // Make sure every sub-entity includes the prop "id" and all updated fields, to allow entities at all levels to be updated in the Apollo cache
    return useQuery(
        gql`
            ${FullNarrativeFragment}
            query narrativeById($projectId: Int!, $id: ID!) {
                narrativeById(projectId: $projectId, id: $id) {
                    ...FullNarrativeFragment
                }
            }
        `,
        {
            skip,
            variables: {
                projectId,
                id,
            },
        },
    )
}
