import { Field } from 'react-final-form'
import SwitchAdapter from '../../../controls/inputs/dealnarrative/SwitchAdapter'
import React from 'react'

type Props = {
    name: string
    initialValue: boolean
    className?: string
    children?: React.ReactNode
}

export default function NarrativeFormSwitch({ name, initialValue, className, children }: Props) {
    return (
        <div className={`NarrativeFormSwitch ${className || ''}`}>
            <Field
                id={name}
                component={SwitchAdapter}
                name={name}
                initialValue={initialValue}
                className={'switchControl'}
            />
            <label className="switchLabel" htmlFor={name}>
                {children}
            </label>
        </div>
    )
}
