import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import Switch from 'react-switch'

// @ts-ignore
export default function SwitchAdapter({ input, meta, className }: FieldRenderProps<any>) {
    const hasError = meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)

    const handleChange = React.useCallback(
        (checked) => {
            input.onChange(checked)
        },
        [input],
    )

    return (
        <React.Fragment>
            <Switch
                checked={input.value}
                onChange={handleChange}
                height={18}
                width={26}
                offColor={'#97A2B4'}
                handleDiameter={10}
                uncheckedIcon={false}
                checkedIcon={false}
                className={className}
            />
            {hasError && (
                <span className={'errorText'}>
                    {meta.error}
                    {meta.submitError}
                </span>
            )}
            {/*language=SCSS*/}
            <style jsx>{`
                .errorText {
                    color: #cf6679;
                    position: absolute;
                    font-size: 11px;
                }
            `}</style>
        </React.Fragment>
    )
}
