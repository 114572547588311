import React from 'react'

type Props = {
    children?: React.ReactNode
}

export default function NarrativeFormContainer(props: Props) {
    const { children } = props

    return (
        <div className={'NarrativeFormContainer container-fluid'}>
            <div className={'row justify-content-center'}>
                <div className={'col-md-9'}>
                    <div className={'container-fluid'}>{children}</div>
                </div>
            </div>
        </div>
    )
}
