import React from 'react'

type Props = {
    table: any
    showColumsSelection?: boolean
    columns?: string
    onChange?: Function
}

export default function CollectionsYearsTable(props: Props) {
    const { table, showColumsSelection, columns, onChange } = props
    const rows = table.data
    if (rows.length === 0) return

    // Some rows are highlighted, check the label of the first column

    if (table.header) {
        for (let i = rows[0].length - table.header.length; i > 0; i--) {
            table.header.push('')
        }
    }
    //console.log('CollectionsYearsTable', table)
    return (
        <>
            <div
                style={{
                    display: 'block',
                    width: '840px',
                    position: 'relative',
                    margin: '0 auto',
                    fontSize: '12px',
                    lineHeight: '15px',
                }}
            >
                <div>{innerTable(rows.slice(0, 11))}</div>
                <div>{innerTable(rows.slice(11))}</div>
            </div>
        </>
    )
}
function innerTable(rows) {
    return (
        <table className={'RecommendedLoanOptionsTable baseTable'} style={{ border: 'none' }}>
            <thead>
                <tr>
                    {rows[0].map((cell, colIndex) => (
                        <th key={colIndex} className={colIndex === 0 ? 'text-left width300' : 'text-right width50'}>
                            {cell}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.slice(1).map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        className={row.highlighted ? 'highlighted' : '' + (rowIndex == 7 ? ' subheader' : '')}
                    >
                        {row.map((cell, colIndex) => (
                            <td key={colIndex} className={colIndex === 0 ? 'text-left width300' : 'text-right width50'}>
                                {cell.trim()}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    .width300 {
                        width: 140px;
                        min-width: 140px;
                        max-width: 140px;
                    }
                    .width50 {
                        width: 50px;
                        min-width: 50px;
                        max-width: 50px;
                        border: none !important;
                    }
                `}
            </style>
        </table>
    )
}
