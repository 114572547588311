import React, { useCallback } from 'react'
import InputAdapter from '../../../controls/inputs/dealnarrative/InputAdapter'
import { Field } from 'react-final-form'
import NarrativeFormField from './NarrativeFormField'
import NarrativeFormSection from './NarrativeFormSection'
import NarrativeFormSubSection from './NarrativeFormSubSection'
import { REG_EXP } from '../../../../utils/dealnarrative/constants/regExps'
import SelectAdapter from '../../../controls/inputs/dealnarrative/SelectAdapter'
import ImageUploadAdapter from '../../../controls/inputs/dealnarrative/ImageUploadAdapter'
import NarrativeForm from './NarrativeForm'
import { NumberField } from '../../dashboard/components/NumberField'

type Props = {
    narrative: any
    onSubmit: Function
    formId: string
}

const FIELD_MAX_LENGTH = 255

export default function OpeningForm(props: Props) {
    const { narrative, onSubmit, formId } = props

    const validateForm = useCallback((values) => {
        const errors: any = {}
        if (!!values.loanAmount && !values.loanAmount.toString().match(REG_EXP.NUMERIC)) {
            errors.loanAmount = 'Digits only (0-9)'
        }
        return errors
    }, [])

    return (
        <NarrativeForm narrative={narrative} onSubmit={onSubmit} formId={formId} validate={validateForm}>
            <NarrativeFormSection title={'Opening Page'}>
                <NarrativeFormField htmlFor={'dealName'} label={'Deal Name'}>
                    <Field
                        id={'dealName'}
                        component={InputAdapter}
                        name={'dealName'}
                        initialValue={narrative?.dealName || ''}
                        className="form-control"
                        maxLength={FIELD_MAX_LENGTH}
                    />
                </NarrativeFormField>
                <NarrativeFormField htmlFor={'projectImage'} label={'Project Image'} fullWidth>
                    <Field
                        id={'projectImage'}
                        component={ImageUploadAdapter}
                        name={'projectImage'}
                        initialValue={narrative?.projectImage || ''}
                        className="large-image project-image"
                    />
                </NarrativeFormField>
            </NarrativeFormSection>
            <NarrativeFormSection title={'Deal Information'}>
                <NarrativeFormSubSection title={'Property Information'}>
                    <NarrativeFormField htmlFor={'propertyName'} label={'Property Name'}>
                        <Field
                            id={'propertyName'}
                            component={InputAdapter}
                            name={'propertyName'}
                            initialValue={narrative?.propertyName || ''}
                            className="form-control"
                            maxLength={FIELD_MAX_LENGTH}
                        />
                    </NarrativeFormField>
                    <NarrativeFormField htmlFor={'propertyAddress'} label={'Property Address'}>
                        <Field
                            id={'propertyAddress'}
                            component={InputAdapter}
                            name={'propertyAddress'}
                            initialValue={narrative?.propertyAddress || ''}
                            className="form-control"
                            maxLength={FIELD_MAX_LENGTH}
                        />
                    </NarrativeFormField>
                    <NarrativeFormField htmlFor={'msa'} label={'MSA'}>
                        <Field
                            id={'msa'}
                            component={InputAdapter}
                            name={'msa'}
                            initialValue={narrative?.msa || ''}
                            className="form-control"
                            maxLength={FIELD_MAX_LENGTH}
                        />
                    </NarrativeFormField>
                    <NarrativeFormField htmlFor={'propertyType'} label={'Property Type'}>
                        <Field
                            id={'propertyType'}
                            component={SelectAdapter}
                            name="propertyType"
                            initialValue={narrative?.propertyType || ''}
                            className="form-control"
                        >
                            <option value="">Select Property Type</option>
                            <option value="Conventional Multifamily">Conventional Multifamily</option>
                            <option value="Limited Equity Cooperative">Limited Equity Cooperative</option>
                            <option value="Manufactured Housing Community">Manufactured Housing Community</option>
                            <option value="Market-Cooperative">Market-Cooperative</option>
                            <option value="Military">Military</option>
                            <option value="Seniors">Seniors</option>
                            <option value="Student - Dedicated">Student - Dedicated</option>
                            <option value="Student - Non-Dedicated">Student - Non-Dedicated</option>
                        </Field>
                    </NarrativeFormField>
                    <NarrativeFormField htmlFor={'dealType'} label={'Deal Type'}>
                        <Field
                            id={'dealType'}
                            component={SelectAdapter}
                            name="dealType"
                            initialValue={narrative?.dealType || ''}
                            className="form-control"
                        >
                            <option value="">Select Deal Type</option>
                            <option value="Acquisition">Acquisition</option>
                            <option value="Refinance">Refinance</option>
                            <option value="Cash-out Refinance">Cash-out Refinance</option>
                        </Field>
                    </NarrativeFormField>
                </NarrativeFormSubSection>
                <NarrativeFormSubSection title={'Loan Details'}>
                    <NarrativeFormField htmlFor={'loanAmount'} label={'Loan Amount'} halfWidth>
                        <NumberField
                            name={`loanAmount`}
                            cssClassName={'form-control loan-amount'}
                            numberFormat={'currency'}
                            defaultValue={narrative?.loanAmount || 0}
                        />
                    </NarrativeFormField>
                </NarrativeFormSubSection>
                {/*
                <NarrativeFormSubSection title={'Northmarq Team'}>
                    <NarrativeFormField htmlFor={'chiefUnderwriter'} label={'Reviewing Chief Underwriter'}>
                        <Field
                            id={'chiefUnderwriter'}
                            component={InputAdapter}
                            name={'chiefUnderwriter'}
                            initialValue={narrative?.chiefUnderwriter || ''}
                            className="form-control"
                            maxLength={FIELD_MAX_LENGTH}
                        />
                    </NarrativeFormField>
                </NarrativeFormSubSection>
                */}
            </NarrativeFormSection>
        </NarrativeForm>
    )
}
