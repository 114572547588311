import React, { useCallback } from 'react'
import { steps } from './steps'
import getImgPath from '../../../../utils/dealnarrative/getImgPath'
import BigBlueBullet from '../../../../theme/dealnarrative/icons/big-blue-dot.png'
import BigGrayBullet from '../../../../theme/dealnarrative/icons/big-gray-dot.png'

type Props = {
    currentStepIndex?: number
    narrative?: any
    onSaveAndExit?: Function
    canSave?: boolean
    onClickStep: Function
}

export default function Menu(props: Props) {
    const { currentStepIndex, narrative, onSaveAndExit, canSave = false, onClickStep } = props

    const dealName = narrative?.dealName
    const projectImage = narrative?.projectImage
    const projectId = narrative?.projectId

    const onClickSaveAndExit = useCallback(
        (event) => {
            event.preventDefault()
            if (!!onSaveAndExit) onSaveAndExit()
        },
        [onSaveAndExit],
    )

    return (
        <div className="Menu d-flex flex-column">
            <h1 className="title">Deal Narrative</h1>
            <h2 className="dealName">{dealName ? dealName : <>&lt;Deal name&gt;</>}</h2>
            {projectImage && (
                <img className={'projectImage'} src={getImgPath(projectImage, projectId)} alt={'Project Image'} />
            )}
            <nav className="nav flex-column">
                {steps.map((step, index) => {
                    let menuClass = 'next'
                    if (currentStepIndex === index) menuClass = 'current'
                    else if (currentStepIndex > index) menuClass = 'previous'

                    return (
                        <div className={`menuItemContainer ${menuClass}`} key={'step' + index}>
                            <div className={'progressColumn'}>
                                <div className={'itemBullet'}>
                                    <img src={menuClass === 'next' ? BigGrayBullet : BigBlueBullet} alt={'Dot'} />
                                </div>
                                {index < steps.length - 1 && <div className={'bottomConnector'} />}
                            </div>
                            <div className={'itemLink'}>
                                <a key={index} className={'nav-link'} href="#" onClick={() => onClickStep(step.id)}>
                                    {step.name}
                                </a>
                            </div>
                        </div>
                    )
                })}
            </nav>
            <div className="mt-auto" style={{ display: 'none' }}>
                <button className="btn btn-outline-primary" onClick={onClickSaveAndExit} disabled={!canSave}>
                    Save & Exit
                </button>
            </div>
        </div>
    )
}
