import React from 'react'
import TableFormatter from './TableFormatter'

type Props = {
    table: any
    columns?: string
    onChange?: Function
}

export default function NarrativeFormTable(props: Props) {
    const { table, columns, onChange } = props
    return (
        <div className={'NarrativeFormTable'}>
            {table ? (
                <TableFormatter table={table} showColumsSelection={true} columns={columns} onChange={onChange} />
            ) : (
                <>Cannot find this table.</>
            )}
        </div>
    )
}
