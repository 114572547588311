import React from 'react'

type Props = {
    table: any
    showColumsSelection?: boolean
    columns?: string
    onChange?: Function
}

export default function AffordabilityEstimatorTable(props: Props) {
    const { table, showColumsSelection, columns, onChange } = props

    const rows = table.data
    if (rows.length === 0) return

    return (
        <table className={'AffordabilityEstimator baseTable'}>
            <thead>
                <tr>
                    {rows[0].map((cell, colIndex) => (
                        <th key={colIndex} className={colIndex === 0 ? 'text-left' : 'text-center'}>
                            {cell}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.slice(1).map((row, rowIndex) => (
                    <tr key={rowIndex} className={row.highlighted ? 'highlighted' : ''}>
                        {row.map((cell, colIndex) => (
                            <td key={colIndex} className={colIndex === 0 ? 'text-left' : 'text-center greenPercent'}>
                                {cell}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
