import React, { useCallback } from 'react'

type Props = {
    onSave?: Function
    onNext?: Function
    onPrevious?: Function
    onPreview?: Function
    onPublish?: Function
    onSaveAndExit?: Function
    canSave?: boolean
}

export default function EditorButtonBar(props: Props) {
    const { onSave, onNext, onPrevious, onPreview, onPublish, onSaveAndExit, canSave = false } = props

    const onClickSave = useCallback(
        (event) => {
            event.preventDefault()
            if (!!onSave) onSave()
        },
        [onSave],
    )

    const onClickPreview = useCallback(
        (event) => {
            event.preventDefault()
            if (!!onPreview) onPreview()
        },
        [onPreview],
    )

    const onClickPrevious = useCallback(
        (event) => {
            event.preventDefault()
            if (!!onPrevious) onPrevious()
        },
        [onPrevious],
    )

    const onClickNext = useCallback(
        async (event) => {
            event.preventDefault()
            if (!!onNext) onNext()
        },
        [onNext],
    )

    const onClickPublish = useCallback(
        (event) => {
            event.preventDefault()
            if (!!onPublish) onPublish()
        },
        [onPublish],
    )

    const onClickProjectList = useCallback(
        (event) => {
            event.preventDefault()
            if (!!onSaveAndExit) onSaveAndExit()
        },
        [onSaveAndExit],
    )
    return (
        <div className="EditorButtonBar ButtonBar d-flex justify-content-between">
            <div>
                {!!onPrevious && (
                    <button
                        type="button"
                        onClick={onClickPrevious}
                        disabled={!canSave}
                        className="btn btn-outline-primary"
                    >
                        Previous
                    </button>
                )}
            </div>
            <div className="ml-auto">
                <button
                    type={'button'}
                    onClick={onClickProjectList}
                    disabled={!canSave}
                    className="btn btn-outline-primary"
                >
                    Go To Projects List
                </button>
                <button type={'button'} onClick={onClickSave} disabled={!canSave} className="btn btn-outline-primary">
                    Save
                </button>
                <button
                    type={'button'}
                    onClick={onClickPreview}
                    disabled={!canSave}
                    className="btn btn-outline-primary"
                >
                    Preview
                </button>
                {!!onNext ? (
                    <button type={'button'} onClick={onClickNext} disabled={!canSave} className="btn btn-primary">
                        Next
                    </button>
                ) : (
                    <button type={'button'} onClick={onClickPublish} disabled={!canSave} className="btn btn-primary">
                        Publish Narrative
                    </button>
                )}
            </div>
        </div>
    )
}
