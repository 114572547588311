import React from 'react'

type Props = {
    table: any
    showColumsSelection?: boolean
    columns?: string
    onChange?: Function
}

export default function UnitMixTable(props: Props) {
    const { table, showColumsSelection, columns, onChange } = props

    const rows = table.data
    if (rows.length === 0) return

    // Some rows are highlighted, some are surrounded by borders, check the label of the first column
    rows.forEach((row) => {
        const firstCell = row.length > 0 ? row[0] : ''

        if (firstCell && firstCell.toString().indexOf('Total / Avg') > -1) row.highlighted = true
    })

    const header = []
    let tmpName = null
    let tmpC = 1
    for (const cell of rows[0]) {
        if (cell != '') {
            if (tmpName) {
                header.push([tmpName, tmpC])
            }
            tmpName = cell
            tmpC = 1
        } else {
            tmpC++
        }
    }
    header.push([tmpName, tmpC])

    return (
        <table className={'UnitMixTable baseTable'}>
            <thead>
                <tr>
                    {header.map((cell, cellIdx) => {
                        if (cell && cell != '')
                            return (
                                <th key={'th' + cellIdx} colSpan={cell[1]} className={'text-center'}>
                                    {cell[0]}
                                </th>
                            )
                        else return null
                    })}
                </tr>
            </thead>
            <tbody>
                {rows.slice(1, 2).map((row, rowIndex) => (
                    <tr key={rowIndex} className={'subheader'}>
                        {row.map((cell, colIndex) => (
                            <td key={colIndex} className={'subheader text-center'}>
                                {cell}
                            </td>
                        ))}
                    </tr>
                ))}
                {rows.slice(2).map((row, rowIndex) => (
                    <tr key={rowIndex} className={`${row.highlighted ? 'highlighted' : ''}`}>
                        {row.map((cell, colIndex) => (
                            <td key={colIndex} className={colIndex === 0 ? 'text-left' : 'text-right'}>
                                {cell}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
