import { gql, useMutation } from '@apollo/client'
import { FullNarrativeFragment } from '../../fragments/dealnarrative/fragments'

export default function useMutationCreateNarrative() {
    // Make sure every sub-entity includes the prop "id" and all updated fields, to allow entities at all levels to be updated in the Apollo cache
    return useMutation(
        gql`
            ${FullNarrativeFragment}
            mutation createNarrative($projectId: Int!, $data: CreateNarrativeInput!) {
                createNarrative(projectId: $projectId, data: $data) {
                    ...FullNarrativeFragment
                }
            }
        `,
    )
}
