export enum NarrativeSectionItemType {
    BLURB = 'BLURB',
    IMAGE = 'IMAGE',
    TABLE = 'TABLE',
    GALLERY = 'GALLERY',
}

export enum NarrativeTableType {
    FIXED_LOAN_OPTIONS = 'FIXED_LOAN_OPTIONS',
    FIXED_LOAN_OPTIONS2 = 'FIXED_LOAN_OPTIONS2',
    FIXED_RATE_SUMMARY = 'FIXED_RATE_SUMMARY',
    FIXED_ADDITIONAL_DATA_POINTS = 'FIXED_ADDITIONAL_DATA_POINTS',

    SUPPLEMENTAL_LOAN_OPTIONS = 'SUPPLEMENTAL_LOAN_OPTIONS',
    SUPPLEMENTAL_LOAN_OPTIONS2 = 'SUPPLEMENTAL_LOAN_OPTIONS2',
    SUPPLEMENTAL_RATE_SUMMARY = 'SUPPLEMENTAL_RATE_SUMMARY',
    SUPPLEMENTAL_ADDITIONAL_DATA_POINTS = 'SUPPLEMENTAL_ADDITIONAL_DATA_POINTS',

    ADJUSTABLE_LOAN_OPTIONS = 'ADJUSTABLE_LOAN_OPTIONS',
    ADJUSTABLE_LOAN_OPTIONS2 = 'ADJUSTABLE_LOAN_OPTIONS2',
    ADJUSTABLE_RATE_SUMMARY = 'ADJUSTABLE_RATE_SUMMARY',
    ADJUSTABLE_ADDITIONAL_DATA_POINTS = 'ADJUSTABLE_ADDITIONAL_DATA_POINTS',

    AFFORDABILITY_ESTIMATOR = 'AFFORDABILITY_ESTIMATOR',
    UNIT_MIX = 'UNIT_MIX',
    PROJECTED_LEASE_UP_SCHEDULE = 'PROJECTED_LEASE_UP_SCHEDULE',
    HISTORICAL_LEASE_UP_SCHEDULE = 'HISTORICAL_LEASE_UP_SCHEDULE',
    RENT_ANALYSIS = 'RENT_ANALYSIS',

    REIS_DATA_INPUT = 'REIS_DATA_INPUT',
    MARKET_UNIT_MIX_RENT_DETAILS = 'MARKET_UNIT_MIX_RENT_DETAILS',
    MARKET_UNIT_MIX_RENT_DETAILS2 = 'MARKET_UNIT_MIX_RENT_DETAILS2',

    COLLECTIONS = 'COLLECTIONS',
    UNDERWRITING_ASSUMPTIONS = 'UNDERWRITING_ASSUMPTIONS',
    EXIT_SCR = 'EXIT_SCR',
    COLLECTIONS_YEARS = 'COLLECTIONS_YEARS',
    MOST_RECENT_MONTH_OF_COLLECTIONS = 'MOST_RECENT_MONTH_OF_COLLECTIONS',
    MOST_RECENT_MONTH_OF_COLLECTIONS_SMOOTH = 'MOST_RECENT_MONTH_OF_COLLECTIONS_SMOOTH',

    DECLINING_COLLECTIONS_ANALYSIS = 'DECLINING_COLLECTIONS_ANALYSIS',
    DECLINING_COLLECTIONS_ANALYSIS_SMOOTH = 'DECLINING_COLLECTIONS_ANALYSIS_SMOOTH',
    REFINANCE_INFORMATION = 'REFINANCE_INFORMATION',
    COST_BASIS = 'COST_BASIS',
    PROPERTY_DETAILS = 'PROPERTY_DETAILS',

    LEASE_UP = 'LEASE_UP'
}
