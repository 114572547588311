import React, { useCallback, useMemo, useState } from 'react'

type Props = {
    table: any
    showColumsSelection?: boolean
    columns?: string
    onChange?: Function
}

export default function RateSummaryTable(props: Props) {
    const { table, showColumsSelection, columns, onChange } = props

    const rows = table.data
    if (rows.length === 0) return

    const columnsCheckedArray = useMemo(() => {
        const columnsCheckedArray = new Array(rows[0].length)
        if (!columns) columnsCheckedArray.fill(true)
        else {
            columnsCheckedArray.fill(false)
            columns.split(',').map((item, idx) => {
                columnsCheckedArray[+item] = true
            })
        }
        return columnsCheckedArray
    }, [columns])

    const [checkedState, setCheckedState] = useState(columnsCheckedArray)

    const onchange = useCallback(
        (idx) => {
            //console.log('onchange', idx)
            const updatedCheckedState = checkedState.map((item, index) => (index === idx ? !item : item))

            setCheckedState(updatedCheckedState)
            let updatedCheckedStateStr = ''
            updatedCheckedState.map((item, index) => {
                if (item) {
                    if (updatedCheckedStateStr == '') updatedCheckedStateStr += index
                    else updatedCheckedStateStr += ',' + index
                }
            })
            onChange(updatedCheckedStateStr)
            //console.log('checked', updatedCheckedState, updatedCheckedStateStr)
        },
        [checkedState, columnsCheckedArray],
    )

    // Some rows are highlighted, some are surrounded by borders, check the label of the first column
    rows.forEach((row) => {
        const firstCell = row.length > 0 ? row[0] : ''

        if (firstCell === 'Total Spread over Index' || firstCell === 'Note Rate') row.highlighted = true
        if (firstCell === 'Pricing Waiver') row.bordered = true
    })
    if (table.header) {
        for (let i = rows[0].length - table.header.length; i > 0; i--) {
            table.header.push('')
        }
    }

    if (table.header2) {
        for (let i = rows[0].length - table.header2.length; i > 0; i--) {
            table.header2.push('')
        }
    }
    return (
        <table className={'RateSummaryTable baseTable'}>
            <thead>
                {showColumsSelection && (
                    <tr>
                        {rows[0].map((cell, colIndex) => (
                            <th key={colIndex} className={colIndex === 0 ? 'text-left' : 'text-right'}>
                                {colIndex > 0 && (
                                    <div>
                                        <div>show</div>
                                        <div>
                                            <input
                                                type={'checkbox'}
                                                style={{ verticalAlign: 'middle', marginRight: '10px' }}
                                                checked={checkedState[colIndex]}
                                                onChange={() => onchange(colIndex)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </th>
                        ))}
                    </tr>
                )}
                <tr>
                    {table?.header?.map((cell, colIndex) => (
                        <th key={colIndex} className={colIndex === 0 ? 'text-left' : 'text-right'}>
                            {cell}
                        </th>
                    ))}
                </tr>
                {table?.header2 && (
                    <tr>
                        {table?.header2.map((cell, colIndex) => {
                            return (
                                <th key={colIndex} className={colIndex === 0 ? 'text-left' : 'text-right'}>
                                    {showColumsSelection || checkedState[colIndex] ? cell : ''}
                                </th>
                            )
                        })}
                    </tr>
                )}
                <tr>
                    {rows[0].map((cell, colIndex) => (
                        <th key={colIndex} className={colIndex === 0 ? 'text-left' : 'text-right'}>
                            {showColumsSelection || checkedState[colIndex] ? cell : ''}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.slice(1).map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        className={`${row.highlighted ? 'highlighted' : ''} ${row.bordered ? 'bordered' : ''}`}
                    >
                        {row.map((cell, colIndex) => (
                            <td key={colIndex} className={colIndex === 0 ? 'text-left' : 'text-right'}>
                                {showColumsSelection || checkedState[colIndex] ? cell : ''}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
