import { gql, useQuery } from '@apollo/client'

export default function useQueryTables(projectId: number, skip = false, narrativeId: number) {
    // Make sure every sub-entity includes the prop "id" and all updated fields, to allow entities at all levels to be updated in the Apollo cache
    return useQuery(
        gql`
            query dealNarrativeTables($projectId: Int!, $narrativeId: ID!) {
                dealNarrativeTables(projectId: $projectId, narrativeId: $narrativeId) {
                    address
                    tables {
                        id
                        type
                        title
                        data
                        header
                        header2
                    }
                }
            }
        `,
        {
            skip,
            // fetchPolicy: 'no-cache',
            variables: {
                projectId,
                narrativeId,
            },
        },
    )
}
