import React from 'react'

type Props = {
    table: any
    showColumsSelection?: boolean
    columns?: string
    onChange?: Function
}

export default function MostRecentMonthofCollectionsTable(props: Props) {
    const { table, showColumsSelection, columns, onChange } = props
    const rows = table.data
    if (rows.length === 0) return

    if (table.header) {
        for (let i = rows[0].length - table.header.length; i > 0; i--) {
            table.header.push('')
        }
    }
    return (
        <table className={'RecommendedLoanOptionsTable baseTable'}>
            <thead>
                <tr>
                    {table?.header?.map((cell, colIndex) => (
                        <th key={colIndex} className={colIndex === 0 ? 'text-left' : 'text-right'}>
                            {cell}
                        </th>
                    ))}
                </tr>
                <tr>
                    {['', 'T-12', 'T-9', 'T-6', 'T-3', 'T-1', 'UW'].map((cell, colIndex) => (
                        <th key={colIndex} className={colIndex === 0 ? 'text-left' : 'text-right'}>
                            {cell}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex} className={rowIndex == 4 ? 'boldText' : ''}>
                        {row.map((cell, colIndex) => (
                            <td key={colIndex} className={colIndex === 0 ? 'text-left boldText' : 'text-right'}>
                                {cell}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    .boldText {
                        font-weight: 600;
                    }
                `}
            </style>
        </table>
    )
}
