import React, { useMemo, useState } from 'react'
import SelectableTable from './SelectableTable'
import { NarrativeTableType } from '../../../../store/types/dealnarrative'

type Props = {
    onSelectTable: Function
    tables: any
}

export default function SelectTable(props: Props) {
    const { onSelectTable, tables } = props
    const [address, setAddress] = useState(0)
    const options = useMemo(() => {
        const options = []
        if (!tables || tables.length == 0) {
            options.push(
                <option value={0} key={0}>
                    default
                </option>,
            )
        }
        for (let i = 0; i < tables.length; i++) {
            options.push(
                <option value={i} key={i}>
                    {tables[i].address}
                </option>,
            )
        }
        return options
    }, [tables])

    const orderedTables = useMemo(() => {
        // Show tables in this order
        if (!tables || tables.length == 0) return []
        const selectedTables = tables[address].tables
        const fixedLoanOptionsTable = selectedTables.find(
            (table) => table.type === NarrativeTableType.FIXED_LOAN_OPTIONS,
        )
        const fixedRateSummaryTable = selectedTables.find(
            (table) => table.type === NarrativeTableType.FIXED_RATE_SUMMARY,
        )
        const fixedAdditionalDataPoints = selectedTables.find(
            (table) => table.type === NarrativeTableType.FIXED_ADDITIONAL_DATA_POINTS,
        )
        const fixedLoanOptionsTable2 = selectedTables.find(
            (table) => table.type === NarrativeTableType.FIXED_LOAN_OPTIONS2,
        )

        const supplementalLoanOptionsTable = selectedTables.find(
            (table) => table.type === NarrativeTableType.SUPPLEMENTAL_LOAN_OPTIONS,
        )
        const supplementalRateSummaryTable = selectedTables.find(
            (table) => table.type === NarrativeTableType.SUPPLEMENTAL_RATE_SUMMARY,
        )
        const supplementaleAdditionalDataPoints = selectedTables.find(
            (table) => table.type === NarrativeTableType.SUPPLEMENTAL_ADDITIONAL_DATA_POINTS,
        )
        const supplementalLoanOptionsTable2 = selectedTables.find(
            (table) => table.type === NarrativeTableType.SUPPLEMENTAL_LOAN_OPTIONS2,
        )

        const adjustableLoanOptionsTable = selectedTables.find(
            (table) => table.type === NarrativeTableType.ADJUSTABLE_LOAN_OPTIONS,
        )
        const adjustableRateSummaryTable = selectedTables.find(
            (table) => table.type === NarrativeTableType.ADJUSTABLE_RATE_SUMMARY,
        )
        const adjustableAdditionalDataPoints = selectedTables.find(
            (table) => table.type === NarrativeTableType.ADJUSTABLE_ADDITIONAL_DATA_POINTS,
        )
        const adjustableLoanOptionsTable2 = selectedTables.find(
            (table) => table.type === NarrativeTableType.ADJUSTABLE_LOAN_OPTIONS2,
        )

        const affordabilityEstimatorTable = selectedTables.find(
            (table) => table.type === NarrativeTableType.AFFORDABILITY_ESTIMATOR,
        )
        const unitMixTable = selectedTables.find((table) => table.type === NarrativeTableType.UNIT_MIX)
        const projectedLeaseUpSchedule = selectedTables.find(
            (table) => table.type === NarrativeTableType.PROJECTED_LEASE_UP_SCHEDULE,
        )
        const hisoricalLeaseUpSchedule = selectedTables.find(
            (table) => table.type === NarrativeTableType.HISTORICAL_LEASE_UP_SCHEDULE,
        )
        const rentAnalysis = selectedTables.find((table) => table.type === NarrativeTableType.RENT_ANALYSIS)
        const collectionsTable = selectedTables.find((table) => table.type === NarrativeTableType.COLLECTIONS)

        const reisDataInput = selectedTables.find((table) => table.type === NarrativeTableType.REIS_DATA_INPUT)
        const marketUnitmixRentDetails = selectedTables.find(
            (table) => table.type === NarrativeTableType.MARKET_UNIT_MIX_RENT_DETAILS,
        )
        const marketUnitmixRentDetails2 = selectedTables.find(
            (table) => table.type === NarrativeTableType.MARKET_UNIT_MIX_RENT_DETAILS2,
        )

        const underwritingAssumptions = selectedTables.find(
            (table) => table.type === NarrativeTableType.UNDERWRITING_ASSUMPTIONS,
        )

        const exitScr = selectedTables.find((table) => table.type === NarrativeTableType.EXIT_SCR)
        const collectionsYears = selectedTables.find((table) => table.type === NarrativeTableType.COLLECTIONS_YEARS)
        const mostRecentMonthofCollections = selectedTables.find(
            (table) => table.type === NarrativeTableType.MOST_RECENT_MONTH_OF_COLLECTIONS,
        )
        const declineCollection = selectedTables.find(
            (table) => table.type === NarrativeTableType.DECLINING_COLLECTIONS_ANALYSIS,
        )
        const mostRecentMonthofCollectionsSmooth = selectedTables.find(
            (table) => table.type === NarrativeTableType.MOST_RECENT_MONTH_OF_COLLECTIONS_SMOOTH,
        )
        const declineCollectionSmooth = selectedTables.find(
            (table) => table.type === NarrativeTableType.DECLINING_COLLECTIONS_ANALYSIS_SMOOTH,
        )

        const refinanceInformation = selectedTables.find(
            (table) => table.type === NarrativeTableType.REFINANCE_INFORMATION,
        )

        const costBasis = selectedTables.find((table) => table.type === NarrativeTableType.COST_BASIS)
        const proprtySummary = selectedTables.find((table) => table.type === NarrativeTableType.PROPERTY_DETAILS)
        const leaseUP = selectedTables.find((table) => table.type === NarrativeTableType.LEASE_UP)

        const orderedTables = [
            { table: fixedLoanOptionsTable, aloneInRow: false },
            { table: fixedRateSummaryTable, aloneInRow: false },
            { table: fixedAdditionalDataPoints, aloneInRow: false },
            { table: fixedLoanOptionsTable2, aloneInRow: false },

            { table: supplementalLoanOptionsTable, aloneInRow: false },
            { table: supplementalRateSummaryTable, aloneInRow: false },
            { table: supplementaleAdditionalDataPoints, aloneInRow: false },
            { table: supplementalLoanOptionsTable2, aloneInRow: false },

            { table: adjustableLoanOptionsTable, aloneInRow: false },
            { table: adjustableRateSummaryTable, aloneInRow: false },
            { table: adjustableAdditionalDataPoints, aloneInRow: false },
            { table: adjustableLoanOptionsTable2, aloneInRow: false },

            { table: affordabilityEstimatorTable, aloneInRow: false },
            // { table: collectionsTable, aloneInRow: false},
            { table: exitScr, aloneInRow: false },

            { table: rentAnalysis, aloneInRow: false },
            { table: unitMixTable, aloneInRow: false },

            { table: mostRecentMonthofCollections, aloneInRow: false },
            { table: declineCollection, aloneInRow: false },

            { table: mostRecentMonthofCollectionsSmooth, aloneInRow: false },
            { table: declineCollectionSmooth, aloneInRow: false },

            { table: collectionsYears, aloneInRow: true },

            { table: reisDataInput, aloneInRow: false },
            { table: underwritingAssumptions, aloneInRow: false },

            { table: marketUnitmixRentDetails, aloneInRow: true },
            { table: marketUnitmixRentDetails2, aloneInRow: true },

            { table: projectedLeaseUpSchedule, aloneInRow: true },
            { table: hisoricalLeaseUpSchedule, aloneInRow: true },
            { table: proprtySummary, aloneInRow: true },
            { table: refinanceInformation, aloneInRow: false },
            { table: costBasis, aloneInRow: false },
            { table: leaseUP, aloneInRow: true },
        ]
        return orderedTables
    }, [address, tables])

    return (
        <>
            <div className={'selectBox'}>
                select address: &nbsp; &nbsp;
                <select
                    onChange={(e) => {
                        e.preventDefault()
                        setAddress(+e.target.value)
                    }}
                >
                    {options}
                </select>
            </div>
            <div className={'row'}>
                {orderedTables.map(({ table, aloneInRow }, index) => (
                    <React.Fragment key={index}>
                        {table && (
                            <div className={'col-12' + (aloneInRow ? '' : ' col-xl-6')}>
                                <SelectableTable table={table} onSelectTable={onSelectTable} />
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
            {/*language=scss*/}
            <style jsx>{`
                .selectBox {
                    margin-bottom: 20px;
                    top: -20px;
                    position: relative;
                    text-align: center;
                }
            `}</style>
        </>
    )
}
