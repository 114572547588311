// Temporary fix for a bug in react-final-form-arrays (see https://github.com/final-form/react-final-form-arrays/issues/138#issuecomment-681958884).
// When adding a section using sectionFields.insert, the <FieldArray> on the section items does not update (there is no itemFields for the new section),
// thus calling itemFields.push to add a blurb/image/table adds nothing.
export const insertAt = ([name, index, value], state, { changeValue }) => {
    changeValue(state, name, (array) => {
        const copy = [...(array || [])]
        copy.splice(index, 0, value)
        return copy
    })
}
