import useQueryTables from '../../../../store/queries/dealnarrative/useQueryTables'
import React, { useMemo } from 'react'
import { useRouter } from 'next/router'

export default function useAvailableTables() {
    // Fetch all tables (should be restricted to some project or address later)
    // Performed only on first component load; subsequent calls look into the Apollo cache (the entity returned must have a prop named "id")
    const router = useRouter()

    const {
        data: tablesData,
        loading,
        error: tablesError,
    } = useQueryTables(+router.query.projectId, !router.query.projectId, +router.query.narrativeId)

    const tables = tablesData?.dealNarrativeTables

    return React.useMemo(() => {
        // Deserialize table data
        const deserializedTables = []
        let loadingError = undefined
        let parsingError = undefined

        if (tablesError) {
            loadingError = 'An error occurred while loading tables'
        } else {
            try {
                if (tables && tables.length > 0) {
                    for (let i = 0; i < tables.length; i++) {
                        deserializedTables.push({
                            address: tables[i].address,
                            tables: tables[i].tables?.map(({ data, header, header2, ...rest }) => {
                                const out = {
                                    data: JSON.parse(data),
                                    header: header ? JSON.parse(header) : null,
                                    header2: header2 ? JSON.parse(header2) : null,
                                    ...rest,
                                }
                                return out
                            }),
                        })
                    }
                }
            } catch (error) {
                console.error(error)
                parsingError = 'An error occured while parsing tables'
            }
        }
        deserializedTables?.map((tables) => {
            tables.tables.map((item) => {
                item.title = item.title.replace(/_/g, ' ')
                const words = item.title.split(' ')
                for (let i = 0; i < words.length; i++) {
                    if (words[i].length > 0) words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase()
                }
                item.title = words.join(' ')
                item.address = tables.address
            })
        })
        return {
            tables: deserializedTables,
            loading,
            error: loadingError || parsingError || undefined,
        }
    }, [tables, tablesError, loading])
}
