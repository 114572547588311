import React from 'react'

type Props = {
    table: any
    showColumsSelection?: boolean
    columns?: string
    onChange?: Function
}

export default function CollectionsTable(props: Props) {
    const { table, showColumsSelection, columns, onChange } = props

    const rows = table.data
    if (rows.length === 0) return

    return (
        <table className={'CollectionsTable baseTable'}>
            <thead>
                <tr />
                <tr>
                    <th />
                    <th className={'text-center'}>T-12</th>
                    <th className={'text-center'}>T-9</th>
                    <th className={'text-center'}>T-6</th>
                    <th className={'text-center'}>T-3</th>
                    <th className={'text-center'}>T-1</th>
                    <th className={'text-center'}>UW</th>
                </tr>
            </thead>
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        <td className={'text-left highlighted'}>{row.title}</td>
                        {row.values?.map((cell, colIndex) => (
                            <td key={colIndex} className={'text-right'}>
                                {formatCell(cell)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

// Temporary cell formatting function to remove some decimals because it makes the table too wide.
// This should be revised depending on the real meaning of the values.
const formatCell = (cell) => {
    // If cell is not a number, output it as is
    if (isNaN(cell)) return cell
    // Else if it's a number and it has decimals, keep only two
    if (cell.toString().match(/\./)) {
        return Math.round(Number(cell) * 100) / 100
    }
}
