import React from 'react'

type Props = {
    table: any
    showColumsSelection?: boolean
    columns?: string
    onChange?: Function
}

export default function ReisDataTable(props: Props) {
    const { table, showColumsSelection, columns, onChange } = props

    const rows = table.data
    if (rows.length === 0) return

    return (
        <React.Fragment>
            <div style={{ display: 'block' }}>
                <div>
                    <table
                        key={'ReisDataTable.baseTable.1'}
                        className={'UnitMixTable baseTable'}
                        style={{ width: '400px' }}
                    >
                        <thead>
                            <tr className={'whiteBG'}>
                                <th colSpan={2} className={'text-center whiteBG'}>
                                    {rows[0][0]}
                                </th>
                                <th colSpan={2} className={'text-center whiteBG'}>
                                    {rows[0][2]}
                                </th>
                            </tr>
                            <tr>
                                <th colSpan={4} className={'text-center'}>
                                    {rows[1][0]}
                                </th>
                            </tr>
                            <tr className={'subheader'}>
                                {rows[2].map((cell, colIndex) => (
                                    <th key={colIndex} className={'subheader text-center'}>
                                        {cell}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.slice(3, 6).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, colIndex) => (
                                        <td key={colIndex} className={'text-center'}>
                                            {cell}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <table
                        key={'ReisDataTable.baseTable.2'}
                        className={'UnitMixTable baseTable'}
                        style={{ width: '400px' }}
                    >
                        <thead>
                            <tr>
                                <th colSpan={4} className={'text-center'}>
                                    {rows[6][0]}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.slice(7, 10).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, colIndex) => (
                                        <td key={colIndex} className={colIndex === 0 ? 'text-left' : 'text-right'}>
                                            {cell}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={4} className={'text-center'}>
                                    {rows[10][0]}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}
