import React from 'react'
import NarrativeFormSection from './NarrativeFormSection'
import NarrativeFormCustomSectionList from './NarrativeFormCustomSectionList'
import NarrativeForm from './NarrativeForm'

type Props = {
    narrative: any
    onSubmit: Function
    formId: string
}

export default function PropertyForm(props: Props) {
    const { narrative, onSubmit, formId } = props

    return (
        <NarrativeForm narrative={narrative} onSubmit={onSubmit} formId={formId}>
            <NarrativeFormSection title={'Property Overview'}>
                <NarrativeFormCustomSectionList sectionName="propertySections" />
            </NarrativeFormSection>
            <NarrativeFormSection title={'Property Management'}>
                <NarrativeFormCustomSectionList sectionName="managementSections" />
            </NarrativeFormSection>
        </NarrativeForm>
    )
}
