import React, { useCallback, useMemo } from 'react'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-calculate'
import { Form } from 'react-final-form'
import NarrativeFormContainer from './NarrativeFormContainer'
import { NarrativeSectionItemType } from '../../../../store/types/dealnarrative'
import cloneDeep from 'lodash/cloneDeep'
import { insertAt } from '../../../utils/dealnarrative/formUtils'
import { merge } from 'lodash'

type Props = {
    narrative: any
    validate?: Function
    onSubmit: Function
    formId: string
    children?: React.ReactNode
}

const focusOnErrors = createDecorator()

const NARRATIVE_SECTIONS = [
    'dealOverviewSections',
    'waiverSections',
    'strengthSections',
    'participantSections',
    'propertySections',
    'affordabilitySections',
    'managementSections',
    'marketSections',
    'underwritingSections',
    'mapSections',
]

export default function NarrativeForm(props: Props) {
    const { narrative, validate, onSubmit, formId, children } = props

    // Convert a narrative to form values
    const initialFormValues = useMemo(() => {
        const newNarrative = narrative ? cloneDeep(narrative) : {}

        // Parse image galleries to individual images
        NARRATIVE_SECTIONS.forEach((narrativeSection) => {
            newNarrative[narrativeSection]?.forEach((section) => {
                section.items?.forEach((item) => {
                    if (item.type === NarrativeSectionItemType.GALLERY) {
                        let urls = []
                        try {
                            urls = JSON.parse(item.value)
                        } catch (e) {
                            // Ignore value when it cannot be parsed
                        }
                        item.value = urls
                    }
                })
            })
        })

        return newNarrative
    }, [narrative])

    // Submit the values after having converted some when required
    const handleSubmit = useCallback(
        async (values /*formApi*/) => {
            const newValues = includeRemovedValues(initialFormValues, values)

            // Convert array of images in GALLERY elements to a single, stringified value,
            // in all sections of the narrative; make sure there is an empty value for every section item
            // (the API requires a value)
            NARRATIVE_SECTIONS.forEach((narrativeSection) => {
                newValues[narrativeSection]?.forEach((section) => {
                    section?.items?.forEach((item /*itemIndex*/) => {
                        if (item.type === NarrativeSectionItemType.BLURB && item.value == null) {
                            item.value = ''
                        } else if (item.type === NarrativeSectionItemType.TABLE && item.value == null) {
                            item.value = ''
                        } else if (item.type === NarrativeSectionItemType.IMAGE && item.value == null) {
                            item.value = ''
                        } else if (item.type === NarrativeSectionItemType.GALLERY) {
                            item.value = JSON.stringify(item.value)
                        }
                    })
                })
            })

            onSubmit(newValues)
        },
        [onSubmit, initialFormValues],
    )

    const validateForm = useCallback(
        (values) => {
            if (validate) return validate(values)
            const errors: any = {}
            return errors
        },
        [validate],
    )

    return (
        <div className="NarrativeForm">
            <Form
                onSubmit={handleSubmit}
                validate={validateForm}
                mutators={{
                    ...arrayMutators,
                    insertAt,
                }}
                initialValues={initialFormValues}
                decorators={[focusOnErrors]}
                render={({ handleSubmit }) => (
                    <form id={formId} onSubmit={handleSubmit}>
                        <NarrativeFormContainer>{children}</NarrativeFormContainer>
                    </form>
                )}
            />
        </div>
    )
}

/**
 * React-final-form does not include empty values when submitting, therefore deleting an input does not send an empty
 * value to the API and the empty value is not saved. This helper compares initial form values with values submitted
 * by react-final-form and then it submits empty values for empty fields, but only when the field had a non-null initial values,
 * so that untouched fields remain untouched.
 * See https://github.com/final-form/react-final-form/issues/130#issuecomment-620291085
 * @param initialValues
 * @param values
 */
const includeRemovedValues = (initialValues: any, values: any) => {
    // For every field initially provided, we check whether it value has been removed
    // and set it explicitly to an empty string
    if (!initialValues) return values
    const initialValuesWithEmptyFields = Object.keys(initialValues).reduce((acc, key) => {
        const value = values[key]

        if (value instanceof Date || (Array.isArray(value) && typeof value[0] !== 'object')) {
            acc[key] = value
        } else if (Array.isArray(value) && typeof value[0] === 'object') {
            acc[key] = value.map((val, itr) => {
                return includeRemovedValues(initialValues[key][itr], val)
            })
        } else if (typeof value === 'object' && value !== null) {
            acc[key] = includeRemovedValues(initialValues[key], value)
        } else {
            acc[key] = typeof value === 'undefined' ? null : value
        }

        return acc
    }, {})

    // Finally, we merge back the values to not miss any which wasn't initially provided
    return merge(initialValuesWithEmptyFields, values)
}
