import React from 'react'

type Props = {
    table: any
    showColumsSelection?: boolean
    columns?: string
    onChange?: Function
}

export default function MarketUnitMixRentDetailsTable2(props: Props) {
    const { table, showColumsSelection, columns, onChange } = props

    const rows = table.data
    if (rows.length === 0) return

    return (
        <table className={'UnitMixTable baseTable'} style={{ width: '800px' }}>
            <thead>
                <tr>
                    <th colSpan={132} className={'text-center'}>
                        {rows[0][0]}
                    </th>
                </tr>
                <tr className={'subheader'}>
                    <th colSpan={10} className={'subheader text-center'}>
                        Current Average Rents and Sizes
                    </th>
                </tr>
                <tr>
                    <th colSpan={4} className={'text-center'}>
                        Subject per Rent Roll
                    </th>
                    <th colSpan={3} className={'text-center'}>
                        REIS
                    </th>
                    <th colSpan={3} className={'text-center'}>
                        Rent Comps
                    </th>
                </tr>
            </thead>
            <tbody>
                {rows.slice(3, 4).map((row, rowIndex) => (
                    <tr key={rowIndex} className={'subheader'}>
                        {row.map((cell, colIndex) => (
                            <td key={colIndex} className={'subheader text-center'}>
                                {cell}
                            </td>
                        ))}
                    </tr>
                ))}
                {rows.slice(4).map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cell, colIndex) => (
                            <td key={colIndex} className={colIndex === 0 ? 'text-left' : 'text-right'}>
                                {cell}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
