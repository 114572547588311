import React from 'react'
import NarrativeFormField from './NarrativeFormField'
import { Field } from 'react-final-form'
import InputAdapter from '../../../controls/inputs/dealnarrative/InputAdapter'

const FIELD_MAX_LENGTH = 255

type Props = {
    name: string
    title: string
    value: string
}

export default function NarrativeFormKpi(props: Props) {
    const { name, title, value } = props

    return (
        <div className={'row align-items-end'}>
            <div className={'col'}>
                <NarrativeFormField htmlFor={`${name}.title`} label={'Title'} fullWidth>
                    <Field
                        id={`${name}.title`}
                        component={InputAdapter}
                        name={`${name}.title`}
                        initialValue={title || ''}
                        className="form-control"
                        maxLength={FIELD_MAX_LENGTH}
                    />
                </NarrativeFormField>
            </div>
            <div className={'col'}>
                <NarrativeFormField htmlFor={`${name}.value`} label={'Value'} fullWidth>
                    <Field
                        id={`${name}.value`}
                        component={InputAdapter}
                        name={`${name}.value`}
                        initialValue={value || ''}
                        className="form-control"
                        maxLength={FIELD_MAX_LENGTH}
                    />
                </NarrativeFormField>
            </div>
        </div>
    )
}
