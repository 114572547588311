import React from 'react'

type Props = {
    className?: string
    title?: string
    children?: React.ReactNode
}

export default function NarrativeFormSubSection(props: Props) {
    const { className, title, children } = props

    return (
        <div className={`NarrativeFormSubSection ${className || ''}`}>
            {title && (
                <>
                    <h3>{title}</h3>
                    <hr />
                </>
            )}
            {children}
        </div>
    )
}
