import React, { useMemo, useCallback } from 'react'
import { FieldRenderProps } from 'react-final-form'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import useMutationUploadNarrativeFile from '../../../../store/queries/dealnarrative/useMutationUploadNarrativeFile'
import ImageIcon from '../../../../theme/dealnarrative/icons/image-upload.png'
import DeleteIcon from '../../../../theme/dealnarrative/icons/delete/m.png'
import getImgPath from '../../../../utils/dealnarrative/getImgPath'
import { useRouter } from 'next/router'

// @ts-ignore
export default function ImageUploadAdapter({
    input,
    meta,
    className,
    initialFilename,
    ...rest
}: FieldRenderProps<any>) {
    const [initialUrl, setInitialUrl] = React.useState(input.value)
    const [images, setImages] = React.useState()
    const maxNumber = 1

    const router = useRouter()
    const projectId = useMemo(() => {
        return +router.query.projectId
    }, [router.query.projectId])

    const [upload, { loading: isUploading, error: uploadError }] = useMutationUploadNarrativeFile()

    const onChange = async (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
        // @ts-ignore
        setImages(imageList as never[])

        if (imageList.length > 0) {
            try {
                const uploadData = await upload({ variables: { projectId: projectId, file: imageList[0].file } })
                input.onChange(uploadData?.data?.uploadNarrativeFile)
            } catch (error) {
                console.error(error)
            }
        }
    }

    const onDelete = () => {
        // @ts-ignore
        setImages([])
        setInitialUrl(undefined)
        input.onChange('')
    }

    const handlePaste = useCallback(async (e) => {
        if (e.clipboardData.files.length) {
            const fileObject = e.clipboardData.files[0]
            try {
                const uploadData = await upload({ variables: { projectId: projectId, file: fileObject } })
                input.onChange(uploadData?.data?.uploadNarrativeFile)

                const fileReader = new FileReader()

                fileReader.onload = function (fileLoadedEvent) {
                    const imageList = new Array()
                    imageList.push({
                        file: fileObject,
                        data_url: fileReader.result,
                    })
                    // @ts-ignore
                    setImages(imageList as never[])
                }
                fileReader.readAsDataURL(fileObject)
            } catch (error) {
                console.error(error)
            }
        } else {
            console.log('No image data was found in your clipboard. Copy an image first or take a screenshot.')
        }
    }, [])

    return (
        <>
            <div className={`ImageUploadAdapter ${className || ''}`}>
                <ImageUploading value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
                    {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => {
                        if (isUploading)
                            return (
                                <div className={'keepRatioWrapper'}>
                                    <div className={'keepRatioContent'}>
                                        <div className={'waitingZone '}>Uploading...</div>
                                    </div>
                                </div>
                            )
                        else if (imageList.length === 0 && !initialUrl) {
                            return (
                                <div className={'keepRatioWrapper'}>
                                    <div className={'keepRatioContent'}>
                                        <div className="dropZone " onClick={onImageUpload} {...dragProps}>
                                            <div>
                                                <img src={ImageIcon} alt={'Upload image'} />
                                            </div>
                                            <div>Click or Drop Image</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else
                            return (
                                <div className={'imageZone '}>
                                    <img
                                        className="uploadedImage"
                                        src={initialUrl ? getImgPath(initialUrl, projectId) : imageList[0]['data_url']}
                                        alt=""
                                    />
                                    <div className={'deleteButtonOverlay'} onClick={() => onDelete()}>
                                        <img src={DeleteIcon} alt={'Delete'} />
                                    </div>
                                </div>
                            )
                    }}
                </ImageUploading>
            </div>
            {(!input?.value || input.value == '') && (
                <div>
                    <input
                        type={'text'}
                        onPaste={handlePaste}
                        placeholder={'Paste inside to upload'}
                        style={{ width: '100%' }}
                    ></input>
                </div>
            )}
        </>
    )
}
