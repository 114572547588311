import { gql } from '@apollo/client'
import { graphQLRequest } from '../../graphQLClient'

export async function generateNarrativeData(projectId: number) {
    const response = await graphQLRequest(
        gql`
            query ($projectId: Int!) {
                generateNarrativeData(projectId: $projectId) {
                    dealOverviewSections
                    dealOverviewSectionsTables
                    propertyOverviewSections
                    propertyOverviewSectionsTables
                    marketOverviewSections
                    marketOverviewSectionsMultiFamilyTables
                    amenitiesList {
                        title
                        list
                    }
                    propertyImages {
                        url
                        text
                    }
                    refinanceText
                    refinanceTables
                    underwritingText
                    underwritingTables
                    sponsorSections
                    omLoanRequest
                    omMarket
                    omProperty
                }
            }
        `,
        {
            projectId,
        },
    )

    return response?.data?.generateNarrativeData
}
