import React, { useCallback, useEffect, useMemo, useState } from 'react'
import EditorButtonBar from '../../components/pages/dealnarrative/navigation/EditorButtonBar'
import Menu from '../../components/pages/dealnarrative/navigation/Menu'
import { steps } from '../../components/pages/dealnarrative/navigation/steps'
import { useRouter } from 'next/router'
import useQueryNarrativeById from '../../store/queries/dealnarrative/useQueryNarrativeById'
import useQueryProjectById from '../../store/queries/dealnarrative/useQueryProjectById'
import querystring from 'querystring'
import ErrorModal from '../../components/pages/dealnarrative/modals/ErrorModal'
import useMutationCreateNarrative from '../../store/queries/dealnarrative/useMutationCreateNarrative'
import useMutationUpdateNarrative from '../../store/queries/dealnarrative/useMutationUpdateNarrative'
import { NarrativeSectionItemType } from '../../store/types/dealnarrative'
import useAvailableTables from '../../components/pages/dealnarrative/tables/useAvailableTables'
import { NextPageContext } from 'next'
import { engineAuthChecker } from '../../utils/server-side/engineAuthChecker'
import { getUserInfo } from '../../utils/server-side/getUserInfo'
import { checkProjectAccess } from '../../utils/access'
import { ROUTE } from '../../utils/constants/routes'
import { getEnv } from '../../utils/server-side/getEnv'

import { reportError } from '../../utils/errorReporting'
import { showNarrativeSavedNotifications } from '../../components/pages/fullproject/notifications'
import { StatusMessage } from '../../components/controls/StatusMessage'

import { store } from '../../store'
import { Provider } from 'react-redux'
import Head from 'next/head'
import { GoogleAnalytics } from '../../components/pages/expose/GoogleAnalytics'
import { generateNarrativeData } from '../../store/queries/dealnarrative/generateNarrativeData'
const cloneDeep = require('lodash.clonedeep')
export default function NarrativeEditorPage(props: any) {
    const router = useRouter()

    const [isPageLoading, setIsPageLoading] = useState(true)
    const [error, setError] = useState(undefined)
    const [currentStepIndex, setCurrentStepIndex] = useState(undefined)
    const [currentSaveAction, setCurrentSaveAction] = useState(undefined)
    // Fill this right after the creation/update action is performed, perform some callback actions, then clear it. Set it to null if an error occurred
    const [lastUpdatedNarrativeId, setLastUpdatedNarrativeId] = useState(undefined)

    // Fetch narrative if a narrativeId is provided in the URL.
    // Performed only after rehydration on the client because router.query is not available until then.
    // Performed only on first page load; subsequent calls look into the Apollo cache (the entity returned must have a prop named "id")
    const {
        data: narrativeData,
        loading: isNarrativeLoading,
        error: narrativeError,
    } = useQueryNarrativeById(+router.query.narrativeId, !router.query.narrativeId, +router.query.projectId)

    // Load available tables to fill the cache and have the user waiting only on first load. Apollo puts that in the cache
    const { loading: isTablesLoading, error: tablesError } = useAvailableTables()

    // If there is no narrativeId to load, initialize an empty narrative with projectId that will be persisted on Save.
    // It requires useMemo to re-use the same object on every render
    const narrative = useMemo(() => {
        if (router.query.narrativeId && !isNarrativeEmpty(narrativeData?.narrativeById)) {
            //narrative is created on the backend in production
            // console.log('found narrative in server' )
            return cloneDeep(narrativeData?.narrativeById)
        }
        //console.log('NOT found narrative in server, return new')
        const tmp = newNarrative(+router.query.projectId)
        // console.log('tmp', tmp)
        //console.log('narrativeData', narrativeData)
        if (narrativeData?.narrativeById) {
            ;[
                'id',
                'dealName',
                'dealType',
                'loanAmount',
                'msa',
                'projectImage',
                'propertyAddress',
                'propertyName',
                'propertyType',
            ].map((item) => {
                if (narrativeData?.narrativeById[item]) tmp[item] = narrativeData?.narrativeById[item]
            })
        }
        // console.log('tmp', tmp)
        return tmp
    }, [router.query.narrativeId, router.query.projectId, narrativeData])

    // Fetch project if a projectId is provided in the URL. See comment above about fetching the narrative.
    const {
        data: projectData,
        loading: isProjectLoading,
        error: projectError,
    } = useQueryProjectById(+router.query.projectId, !router.query.projectId)

    const project = projectData?.projectById

    const [createNarrative, { loading: isCreationLoading, error: creationError }] = useMutationCreateNarrative()
    const [updateNarrative, { loading: isUpdateLoading, error: updateError }] = useMutationUpdateNarrative()
    const [lastMutationData, setlastMutationData] = useState(undefined)

    const isSaving = isCreationLoading || isUpdateLoading

    // Replace the "step=" param and "narrativeId" param in the url query with a new step ID
    const changeRoute = useCallback(
        (stepId, narrativeId) => {
            const newQuery = Object.assign({}, router.query)
            if (stepId) newQuery.step = stepId
            if (narrativeId) newQuery.narrativeId = narrativeId
            const newRoute = `${router.pathname}?${querystring.stringify(newQuery)}`
            router.push(newRoute)
        },
        [router],
    )

    const showReport = useCallback(
        (narrativeId) => {
            //console.log('show report')
            window.open(`report?id=${router?.query?.projectId}&narrativeId=${narrativeId}`, '_blank', 'noopener')
        },
        [router.query.projectId],
    )

    const goToProjectsList = useCallback(() => {
        //console.log('show report')
        window.location.href = `/v1/`
    }, [])

    const onSaveCompleted = useCallback(() => {
        //console.log('onSaveCompleted')
        // Always update route after an operation that might create a new narrative, to get new narrativeId
        if (currentSaveAction?.action === 'next') {
            return changeRoute(currentSaveAction?.nextStepId, lastUpdatedNarrativeId)
        } else if (currentSaveAction?.action === 'save') {
            return changeRoute(undefined, lastUpdatedNarrativeId)
        } else if (currentSaveAction?.action === 'preview') {
            changeRoute(undefined, lastUpdatedNarrativeId)
            return showReport(lastUpdatedNarrativeId)
        } else if (currentSaveAction?.action === 'exit') {
            changeRoute(undefined, lastUpdatedNarrativeId)
            console.log('Exiting narrative (to be implemented)')
            return goToProjectsList()
        } else if (currentSaveAction?.action === 'publish') {
            changeRoute(undefined, lastUpdatedNarrativeId)
            showReport(lastUpdatedNarrativeId)
        }
        setCurrentSaveAction(undefined)
    }, [currentSaveAction, changeRoute, lastUpdatedNarrativeId, showReport, goToProjectsList])

    const onSaveFailed = useCallback(() => {
        setCurrentSaveAction(undefined)
    }, [])

    const onSubmit = useCallback(
        async (values) => {
            //console.log('onSubmit')
            setError(undefined)

            // Shouldn't happen
            if (!narrative) return
            //  console.log('narrative', narrative)
            try {
                if (narrative.id) {
                    // Narrative was loaded, we want to update it
                    const data = {
                        ...postProcessFormValues(values),
                    }
                    setlastMutationData(data)
                    await updateNarrative({
                        variables: {
                            id: narrative.id,
                            projectId: +narrative.projectId,
                            data: data,
                        },
                    })
                    setLastUpdatedNarrativeId(narrative.id)
                    showNarrativeSavedNotifications()
                } else {
                    // Narrative was not loaded, we want to create it.
                    // No need to specify all narrative fields, creation is allowed only from the first page
                    const data = {
                        projectId: +narrative.projectId,
                        ...postProcessFormValues(values),
                    }
                    setlastMutationData(data)
                    const creationResult = await createNarrative({
                        variables: {
                            projectId: +narrative.projectId,
                            data: data,
                        },
                    })
                    setLastUpdatedNarrativeId(creationResult?.data?.createNarrative?.id)
                }
            } catch (error) {
                console.error(error)
                setLastUpdatedNarrativeId(null)
            }
        },
        [narrative, updateNarrative, createNarrative, creationError, updateError],
    )

    //autofill 0 -no, 1 - in progress , 2 - done
    const [isAutoFill, setIsAutoFill] = useState(0)
    // Page is loading until router is ready and we are able to know whether a narrativeId
    // or projectId was provided;
    // in that case, page is loading until fetching the narrative/project is done.
    // Otherwise, page is done loading as soon as the current step has been determined (nothing to load)
    useEffect(() => {
        let pageLoading = isPageLoading
        if (router.isReady && !!router.query.narrativeId) pageLoading = isNarrativeLoading
        else if (router.isReady && !!router.query.projectId) pageLoading = isProjectLoading || isTablesLoading
        else pageLoading = currentStepIndex === undefined
        if (!pageLoading && narrative && narrative.projectId && !isAutoFill) {
            setIsAutoFill(1)
            setLoadingDefaultNarrative(true)
            autoFill(narrative).then(() => {
                // console.log('isPageLoading', isPageLoading, 'pageLoading', pageLoading, 'narrative autofill', narrative)
                setIsAutoFill(2)
                setIsPageLoading(pageLoading)
                setLoadingDefaultNarrative(false)
            })
        }
    }, [
        narrative,
        router.isReady,
        isNarrativeLoading,
        isProjectLoading,
        isTablesLoading,
        router.query.narrativeId,
        router.query.projectId,
        currentStepIndex,
        isPageLoading,
        isAutoFill,
    ])

    // Current step ID is taken from URL so that each step has its own URL (enabling the browser Back/Forward buttons to allow
    // navigation between steps). If URL has no step ID, or if step ID is not from the known set (find returns -1), use step 0.
    // The current step needs to be set in an effect (which runs on the client) because router.query.step is not available at compile time
    // on the NextJS server.
    useEffect(() => {
        if (router.isReady) {
            setCurrentStepIndex(
                Math.max(
                    0,
                    steps.findIndex((step) => step.id === router.query.step),
                ),
            )
        }
    }, [router.isReady, router.query.step])

    // Set error, if any (we must do that in an effect because we depend on router.isReady).
    useEffect(() => {
        //console.log('useEffect')
        if (router.isReady && !router.query.projectId && !router.query.narrativeId) {
            // URL query must either specify projectId (new narrative) or narrativeId (fetch narrative)
            setError('You must provide either a project ID or a narrative ID')
        } else if (router.isReady && router.query.narrativeId) {
            // A fetch with narrativeId was attempted
            if (narrativeError)
                // Fetch ended with errors
                setError('Error in response while fetching the narrative')
            else if (!isNarrativeLoading && !narrative)
                // Fetch ended without error but returned nothing
                setError('Cannot find a narrative with this ID')
            else setError(undefined)
        } else if (router.isReady && router.query.projectId) {
            // A fetch with projectId was attempted
            if (projectError)
                // Fetch ended with errors
                setError('Error in response while fetching the project')
            else if (!isProjectLoading && !project)
                // Fetch ended without error but returned nothing
                setError('Cannot find a project with this ID')
            else setError(undefined)
        } else if (tablesError) {
            // An error occurred during available tables loading
            setError(tablesError)
        } else {
            // New narrative and project exists, so no error until we send the mutation
            setError(undefined)
        }
    }, [
        router.isReady,
        router.query.projectId,
        router.query.narrativeId,
        narrativeError,
        projectError,
        tablesError,
        isNarrativeLoading,
        isProjectLoading,
        narrative,
        project,
    ])

    // User triggers a save action: call the form submit function (so that react-final-form works as designed).
    useEffect(() => {
        //console.log('useEffect2', currentSaveAction)
        if (currentSaveAction) {
            // Beware that any modification to state variables (such as onSubmit) performed during the current
            // rendering won't be applied to the form until the render is done. That is why we need to change
            // another state variable in onSubmit, to trigger another render and use the current currentSaveAction.
            const form = document.getElementById(currentSaveAction.formId)
            const event = new Event('submit', {
                bubbles: true, // Whether the event will bubble up through the DOM or not
                cancelable: true, // Whether the event may be canceled or not
            })
            form.dispatchEvent(event)
        }
    }, [currentSaveAction])

    useEffect(() => {
        if (lastUpdatedNarrativeId !== undefined) {
            if (lastUpdatedNarrativeId !== null) onSaveCompleted()
            else onSaveFailed()

            setLastUpdatedNarrativeId(undefined)
        }
    }, [lastUpdatedNarrativeId, onSaveFailed, onSaveCompleted])

    // An error occurred during save operations
    useEffect(() => {
        if (creationError) {
            setError('Narrative creation failed')
            reportError(
                props.Config.ENV,
                creationError,
                JSON.stringify({
                    ...postProcessFormValues(lastMutationData),
                }),
                JSON.stringify(creationError.graphQLErrors),
            )
        } else if (updateError) {
            let msg = ''
            if (updateError.message.indexOf('Data too long') >= 0) msg = 'Data too long'
            setError('Narrative update failed: ' + msg)
            reportError(
                props.Config.ENV,
                updateError,
                JSON.stringify({
                    ...postProcessFormValues(lastMutationData),
                }),
                JSON.stringify(updateError.graphQLErrors),
            )
        }
    }, [creationError, updateError, lastMutationData])

    let menu: React.ReactNode
    let content: React.ReactNode
    let buttonBar: React.ReactNode = null

    const [isLoadingDefaultNarrative, setLoadingDefaultNarrative] = useState(false)
    if (isPageLoading) {
        // Do not display the form until the page has loaded (router query is ready, we know which step to display,
        // and narrative has been fetched if an ID has been provided in the query)
        menu = <Menu onClickStep={changeRoute} />
        content = isLoadingDefaultNarrative ? (
            <div>Generating Narrative Data for the first time (can take up to 5 minute...)</div>
        ) : (
            <div>Loading...</div>
        )
        buttonBar = <EditorButtonBar />
    } else if (error) {
        // Page is done loading, but errors occurred before displaying the form
        menu = <Menu onClickStep={changeRoute} />
        content = <ErrorModal show={!!error} message={error} onDismiss={() => setError(undefined)} />
    } else {
        // The step param is available in the URL, display the form
        const currentStep = steps[currentStepIndex]

        const CurrentStepComponent = currentStep.component
        const currentStepFormId = `form-${currentStep.id}`

        const previousStepId = currentStepIndex > 0 ? steps[currentStepIndex - 1].id : undefined
        const nextStepId = currentStepIndex < steps.length - 1 ? steps[currentStepIndex + 1].id : undefined

        menu = (
            <Menu
                currentStepIndex={currentStepIndex}
                narrative={narrative}
                onSaveAndExit={() => setCurrentSaveAction({ action: 'exit', formId: currentStepFormId })}
                canSave={!isSaving}
                onClickStep={changeRoute}
            />
        )
        content = (
            <>
                <ErrorModal show={!!error} message={error} onDismiss={() => setError(undefined)} />
                <CurrentStepComponent narrative={narrative} onSubmit={onSubmit} formId={currentStepFormId} />
            </>
        )
        buttonBar = (
            <EditorButtonBar
                onSave={() => setCurrentSaveAction({ action: 'save', formId: currentStepFormId })}
                onPrevious={previousStepId ? () => changeRoute(previousStepId, narrative?.id) : undefined}
                onNext={
                    nextStepId
                        ? () =>
                              setCurrentSaveAction({
                                  action: 'next',
                                  formId: currentStepFormId,
                                  nextStepId: nextStepId,
                              })
                        : undefined
                }
                onPreview={() => {
                    //console.log('preview')
                    setCurrentSaveAction({ action: 'preview', formId: currentStepFormId })
                }}
                onPublish={() => setCurrentSaveAction({ action: 'publish', formId: currentStepFormId })}
                onSaveAndExit={() => setCurrentSaveAction({ action: 'exit', formId: currentStepFormId })}
                canSave={!isSaving}
            />
        )
    }

    return (
        <Provider store={store}>
            <Head>
                <link
                    href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined"
                    rel="stylesheet"
                />
            </Head>
            <div className="NarrativeEditorPage">
                <nav className="sidebar">{menu}</nav>

                <div className="mainContainer">{content}</div>
                <div className="buttonBarContainer">{buttonBar}</div>
                <StatusMessage />
            </div>
            <GoogleAnalytics
                apiKey={props.env.GOOGLE_ANALYTICS_KEY}
                narrativeId={+router?.query?.narrativeId}
                projectId={+router?.query?.projectId}
            />
        </Provider>
    )
}

export async function getServerSideProps(context: NextPageContext) {
    const { Config } = require('../../config')
    try {
        const obj = await engineAuthChecker(context)
        if (obj != null) return obj
    } catch (e) {
        console.error(e)
    }
    const user = await getUserInfo(context)

    // @ts-ignore
    const access = await checkProjectAccess(user?.accountid, +context.query.projectId, user?.isAdmin)

    if (!access) {
        return {
            redirect: {
                destination: ROUTE.LOGIN,
                permanent: false,
            },
        }
    }

    return {
        props: { ...getEnv(), projectId: +context.query.id, accountId: user?.accountid, user, Config },
    }
}

function postProcessFormSectionValues(sectionValues) {
    return sectionValues
        ? sectionValues.map((section, sectionIndex) => ({
              position: sectionIndex,
              title: section.title,
              items: section.items
                  ? section.items
                        // Filter out image items because there is no delete button for them
                        .filter((item) => item.type !== NarrativeSectionItemType.IMAGE || item.value?.length > 0)
                        .map((item, itemIndex) => ({
                            position: itemIndex,
                            type: item.type,
                            value: item.value,
                            showColumns: item.showColumns,
                            address: item.address,
                        }))
                  : [],
              kpis: section.kpis
                  ? section.kpis.map((kpi, kpiIndex) => ({
                        position: kpiIndex,
                        title: kpi.title,
                        value: kpi.value,
                    }))
                  : [],
              visible: section.visible,
          }))
        : []
}

function postProcessDealInformationFormValues(values) {
    if (values.loanAmount && typeof values.loanAmount == 'string') {
        values.loanAmount = values.loanAmount.replace(/\D/g, '')
    }
    return {
        dealName: values.dealName,
        propertyName: values.propertyName,
        propertyAddress: values.propertyAddress,
        projectImage: values.projectImage,
        msa: values.msa,
        dealType: values.dealType,
        propertyType: values.propertyType,
        loanAmount: values.loanAmount == null ? null : +values.loanAmount,
        chiefUnderwriter: values.chiefUnderwriter,
    }
}

function postProcessFormValues(values) {
    return {
        ...postProcessDealInformationFormValues(values),

        // Re-assign position of children, for sorting
        dealOverviewSections: postProcessFormSectionValues(values.dealOverviewSections),
        waiverSections: postProcessFormSectionValues(values.waiverSections),
        strengthSections: postProcessFormSectionValues(values.strengthSections),
        participantSections: postProcessFormSectionValues(values.participantSections),
        propertySections: postProcessFormSectionValues(values.propertySections),
        affordabilitySections: postProcessFormSectionValues(values.affordabilitySections),
        managementSections: postProcessFormSectionValues(values.managementSections),
        marketSections: postProcessFormSectionValues(values.marketSections),
        underwritingSections: postProcessFormSectionValues(values.underwritingSections),
        mapSections: postProcessFormSectionValues(values.mapSections),
    }
}

function isNarrativeEmpty(narrative: any) {
    if (!narrative) return true
    // console.log('isNarrativeEmpty', narrative)
    const sections = [
        'dealOverviewSections',
        'waiverSections',
        'strengthSections',
        'participantSections',
        'propertySections',
        'propertySections',
        'affordabilitySections',
        'managementSections',
        'marketSections',
        'underwritingSections',
        'mapSections',
    ]

    for (const section of sections) {
        if (narrative[section] && narrative[section].length > 0) {
            return false
        }
    }

    return true
}

/**
 * populate data automatyically
 * @param narrative
 */
async function autoFill(narrative: any) {
    let runFetchData = false
    let lastResult = null
    function sleep(ms: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms)
        })
    }
    async function getDefaultData() {
        if (runFetchData) return lastResult
        let count = 0
        while (count < 30) {
            count++
            const res = await generateNarrativeData(+narrative.projectId)
            if (res) {
                lastResult = res
                return res
            }
            await sleep(10 * 1000)
        }
        runFetchData = true
        return null
    }
    let defData = null
    const dealOverview = narrative.dealOverviewSections.find((s: any) => s.title === 'Deal Overview')
    const dealOverviewItems = dealOverview?.items?.filter((it: any) => it.type == NarrativeSectionItemType.BLURB)
    if (
        dealOverview &&
        dealOverviewItems &&
        dealOverviewItems.length == 1 &&
        (!dealOverviewItems[0].value || dealOverviewItems[0].value == '')
    ) {
        if (!defData) defData = await getDefaultData()
        if (defData) {
            dealOverviewItems[0].position = 0
            dealOverviewItems[0].value = defData.dealOverviewSections[0]
            if (defData.dealOverviewSectionsTables[0]) {
                dealOverview.items.push({
                    position: 1,
                    type: NarrativeSectionItemType.TABLE,
                    id: defData.dealOverviewSectionsTables[0] + narrative.propertyAddress,
                    value: defData.dealOverviewSectionsTables[0],
                    showColumns: null,
                    address: narrative.propertyAddress,
                })
            }
            if (defData.dealOverviewSections[1]) {
                dealOverview.items.push({
                    position: 2,
                    type: NarrativeSectionItemType.BLURB,
                    value: defData.dealOverviewSections[1],
                })
                dealOverview.items.push({
                    position: 3,
                    type: NarrativeSectionItemType.TABLE,
                    id: defData.dealOverviewSectionsTables[1] + narrative.propertyAddress,
                    value: defData.dealOverviewSectionsTables[1],
                    showColumns: null,
                    address: narrative.propertyAddress,
                })
            }
            if (defData.omLoanRequest && defData.omLoanRequest != '') {
                narrative.dealOverviewSections.push({
                    title: 'Production Deal Information',
                    items: [{ type: NarrativeSectionItemType.BLURB, value: defData.omLoanRequest }],
                })
            }
        }
    }

    const participantSections = narrative.participantSections.find((s: any) => s.title === 'Participant Overview')
    const participantSectionsItems = participantSections?.items?.filter(
        (it: any) => it.type == NarrativeSectionItemType.BLURB,
    )
    if (
        participantSections &&
        participantSectionsItems &&
        participantSectionsItems.length == 1 &&
        (!participantSectionsItems[0].value || participantSectionsItems[0].value == '')
    ) {
        if (!defData) defData = await getDefaultData()
        if (defData && defData?.sponsorSections?.length > 0) {
            participantSectionsItems[0].position = 0
            participantSectionsItems[0].value = defData.sponsorSections[0]
        }
    }

    if (!narrative.propertySections || narrative.propertySections.length <= 1) {
        const propertyOverview = narrative.propertySections[0]
        const propertyOverviewItems = propertyOverview?.items?.filter(
            (it: any) => it.type == NarrativeSectionItemType.BLURB,
        )
        if (
            propertyOverview &&
            propertyOverviewItems.length == 1 &&
            (!propertyOverviewItems[0].value || propertyOverviewItems[0].value == '')
        ) {
            if (!defData) defData = await getDefaultData()
            if (defData) {
                propertyOverviewItems[0].position = 0
                propertyOverviewItems[0].value = defData.propertyOverviewSections[0]

                if (defData.propertyOverviewSectionsTables) {
                    let position = 1
                    for (const t of defData.propertyOverviewSectionsTables) {
                        propertyOverview.items.push({
                            position: position++,
                            type: NarrativeSectionItemType.TABLE,
                            id: t + narrative.propertyAddress,
                            value: t,
                            showColumns: null,
                            address: narrative.propertyAddress,
                        })
                    }
                }

                if (defData.amenitiesList && defData.amenitiesList.length > 0) {
                    let value = ''

                    for (const a of defData.amenitiesList) {
                        value += `<br><p><div><b>${a.title}</b></div><div><ul style="-webkit-column-count: 3;   -moz-column-count: 3;   column-count: 3;">`
                        for (const l of a.list) {
                            value += `<li>${l}</li>`
                        }
                        value += `</ul></div></p>`
                    }
                    const item = {
                        position: 3,
                        type: NarrativeSectionItemType.BLURB,
                        value: value,
                    }
                    propertyOverview.items.push(item)
                }

                if (defData.omProperty && defData.omProperty != '') {
                    narrative.propertySections.push({
                        title: 'Production Property Information',
                        items: [{ type: NarrativeSectionItemType.BLURB, value: defData.omProperty }],
                    })
                }
            }
        }
    }

    if (!narrative.marketSections || narrative.marketSections.length <= 1) {
        const marketOverview = narrative.marketSections[0]
        const marketOverviewItems = marketOverview?.items?.filter(
            (it: any) => it.type == NarrativeSectionItemType.BLURB,
        )
        if (
            marketOverview &&
            marketOverviewItems &&
            marketOverviewItems.length == 1 &&
            (!marketOverviewItems[0].value || marketOverviewItems[0].value == '')
        ) {
            if (!defData) defData = await getDefaultData()
            if (defData) {
                marketOverview.title = 'Market informaiton '
                marketOverviewItems[0].position = 0
                if (defData.marketOverviewSections?.[0])
                    marketOverviewItems[0].value = defData.marketOverviewSections[0]
                if (defData.marketOverviewSections?.[1]) {
                    const section: any = {
                        title: 'MultiFamily Submarket infotmation ',
                        items: [
                            {
                                type: NarrativeSectionItemType.BLURB,
                                value: defData.marketOverviewSections[1],
                                position: 0,
                            },
                        ],
                    }
                    if (
                        defData.marketOverviewSectionsMultiFamilyTables &&
                        defData.marketOverviewSectionsMultiFamilyTables.length > 0
                    ) {
                        let position = 1
                        for (const t of defData.marketOverviewSectionsMultiFamilyTables) {
                            section.items.push({
                                position: position++,
                                type: NarrativeSectionItemType.TABLE,
                                id: t + narrative.propertyAddress,
                                value: t,
                                showColumns: null,
                                address: narrative.propertyAddress,
                            })
                        }
                    }
                    narrative.marketSections.push(section)
                }
                if (defData.marketOverviewSections?.[2]) {
                    narrative.marketSections.push({
                        title: 'Location informaiton ',
                        items: [{ type: NarrativeSectionItemType.BLURB, value: defData.marketOverviewSections[2] }],
                    })
                }
                if (defData.omMarket && defData.omMarket != '') {
                    narrative.marketSections.push({
                        title: 'Production Market Information',
                        items: [{ type: NarrativeSectionItemType.BLURB, value: defData.omMarket }],
                    })
                }
            }
        }
    }

    const underwritingSections = narrative.underwritingSections.find((s: any) => s.title === 'Underwriting Overview')
    const underwritingItems = underwritingSections?.items?.filter(
        (it: any) => it.type == NarrativeSectionItemType.BLURB,
    )
    if (
        underwritingSections &&
        underwritingItems &&
        underwritingItems.length == 1 &&
        (!underwritingItems[0].value || underwritingItems[0].value == '')
    ) {
        if (!defData) defData = await getDefaultData()
        if (defData) {
            underwritingItems[0].position = 0
            underwritingItems[0].value = defData.underwritingText || ''
            if (defData.underwritingTables) {
                let position = 0
                for (const t of defData.underwritingTables) {
                    underwritingSections.items.push({
                        position: position++,
                        type: NarrativeSectionItemType.TABLE,
                        id: t + narrative.propertyAddress,
                        value: t,
                        showColumns: null,
                        address: narrative.propertyAddress,
                    })
                }
            }
        }
    }

    const refianacneSections = narrative.underwritingSections.find((s: any) => s.title === 'Refinance Analysis')
    const refinanceItems = refianacneSections?.items?.filter((it: any) => it.type == NarrativeSectionItemType.BLURB)
    if (
        refianacneSections &&
        refinanceItems &&
        refinanceItems.length == 1 &&
        (!refinanceItems[0].value || refinanceItems[0].value == '')
    ) {
        if (!defData) defData = await getDefaultData()
        if (defData) {
            refinanceItems[0].position = 0
            refinanceItems[0].value = defData.refinanceText
            if (defData.refinanceTables) {
                let position = 0
                for (const t of defData.refinanceTables) {
                    refianacneSections.items.push({
                        position: position++,
                        type: NarrativeSectionItemType.TABLE,
                        id: t + narrative.propertyAddress,
                        value: t,
                        showColumns: null,
                        address: narrative.propertyAddress,
                    })
                }
            }
        }
    }

    if (!narrative.mapSections) {
        narrative.mapSections = []
    }
    const imgSections = narrative.mapSections.filter((s: any) => s.title == 'Property Photos')
    let imgSection = imgSections?.[0]
    if (!imgSection) {
        imgSection = {
            title: 'Property Photos',
            isMandatory: true,
            items: [{ type: NarrativeSectionItemType.GALLERY, value: JSON.stringify(['', '', '', '']) }],
        }
        narrative.mapSections.push(imgSection)
    }
    if (imgSection.items?.[0] && imgSection.items[0].value == '["","","",""]') {
        if (!defData) defData = await getDefaultData()
        // console.log('defData?.propertyImages', defData?.propertyImages?.length)
        if (defData?.propertyImages) {
            let imgArray: any = []
            imgSection.items = []
            for (const img of defData?.propertyImages) {
                //  console.log('img.url', img.url)
                imgArray.push(img.url)
                if (imgArray.length == 4) {
                    imgSection.items.push({ type: NarrativeSectionItemType.GALLERY, value: JSON.stringify(imgArray) })
                    if (imgSection.items.length == 5) break
                    imgArray = []
                }
            }
            if (imgArray.length > 0) {
                while (imgArray.length < 4) imgArray.push('')
                imgSection.items.push({ type: NarrativeSectionItemType.GALLERY, value: JSON.stringify(imgArray) })
            }
        }
    }

    //console.log('narrative', narrative)
    return narrative
}

/**
 * Create a new narrative, with project ID and default sections
 * @param projectId
 */
function newNarrative(projectId: number) {
    const narrative: any = { projectId }

    // Default overview sections
    narrative.dealOverviewSections = [
        {
            title: 'Deal Overview',
            isMandatory: true,
            items: [{ type: NarrativeSectionItemType.BLURB }],
        },
    ]

    // Default waiver sections
    narrative.waiverSections = [
        {
            title: 'Waivers and Reasons for Pre-Review',
            isMandatory: true,
            items: [{ type: NarrativeSectionItemType.BLURB }],
        },
    ]

    // Default strength sections
    narrative.strengthSections = [
        {
            title: 'Strengths',
            isMandatory: true,
            items: [{ type: NarrativeSectionItemType.BLURB }],
        },
        {
            title: 'Risks and Mitigating Factors',
            isMandatory: true,
            items: [{ type: NarrativeSectionItemType.BLURB }],
        },
    ]

    // Default participant section
    narrative.participantSections = [
        {
            title: 'Participant Overview',
            isMandatory: true,
            showKpis: true,
            items: [{ type: NarrativeSectionItemType.BLURB }],
        },
    ]

    // Default property section
    narrative.propertySections = [
        {
            title: '',
            isMandatory: true,
            items: [{ type: NarrativeSectionItemType.BLURB }],
        },
    ]

    // Default affordability sections
    narrative.affordabilitySections = [
        {
            title: 'Affordability',
            isMandatory: true,
            items: [{ type: NarrativeSectionItemType.BLURB }],
        },
    ]

    // Default management sections
    narrative.managementSections = [
        {
            title: 'Property Management',
            isMandatory: true,
            showKpis: true,
            items: [{ type: NarrativeSectionItemType.BLURB }],
        },
    ]

    // Default market section
    narrative.marketSections = [
        {
            title: 'Property Market and Location',
            isMandatory: true,
            items: [{ type: NarrativeSectionItemType.BLURB }],
        },
    ]

    // Default underwriting section
    narrative.underwritingSections = [
        {
            title: 'Underwriting Overview',
            isMandatory: true,
            items: [{ type: NarrativeSectionItemType.BLURB }],
        },
        {
            title: 'Refinance Analysis',
            isMandatory: true,
            items: [{ type: NarrativeSectionItemType.BLURB }],
        },
        {
            title: 'Competition Quotes',
            isMandatory: true,
            items: [{ type: NarrativeSectionItemType.BLURB }],
        },
        { title: 'Pricing Comments', isMandatory: true, items: [{ type: NarrativeSectionItemType.BLURB }] },
    ]

    // Default maps section
    narrative.mapSections = [
        {
            title: 'Maps & Aerials Photos',
            isMandatory: true,
            items: [{ type: NarrativeSectionItemType.IMAGE }],
        },
        {
            title: 'Property Photos',
            isMandatory: true,
            items: [{ type: NarrativeSectionItemType.GALLERY, value: JSON.stringify(['', '', '', '']) }],
        },
    ]

    return narrative
}
