import React from 'react'

type Props = {
    className?: string
    title?: string
    children?: React.ReactNode
}

export default function NarrativeFormSection(props: Props) {
    const { className, title, children = null } = props

    return (
        <div className={`NarrativeFormSection ${className || ''}`}>
            <h2 className={'NarrativeFormSectionTitle'}>{title}</h2>
            {children}
        </div>
    )
}
