import React from 'react'
import NarrativeFormSection from './NarrativeFormSection'
import NarrativeFormCustomSectionList from './NarrativeFormCustomSectionList'
import NarrativeForm from './NarrativeForm'

type Props = {
    narrative: any
    onSubmit: Function
    formId: string
}

export default function OverviewForm(props: Props) {
    const { narrative, onSubmit, formId } = props

    return (
        <NarrativeForm narrative={narrative} onSubmit={onSubmit} formId={formId}>
            <NarrativeFormSection title={'Deal Overview'}>
                <NarrativeFormCustomSectionList sectionName="dealOverviewSections" />
            </NarrativeFormSection>
            <NarrativeFormSection title={'Waivers and Reasons for Pre-review'}>
                <NarrativeFormCustomSectionList sectionName="waiverSections" />
            </NarrativeFormSection>
            <NarrativeFormSection title={'Strengths, Risks and Mitigation Factors'}>
                <NarrativeFormCustomSectionList sectionName="strengthSections" />
            </NarrativeFormSection>
            <NarrativeFormSection title={'Affordability'}>
                <NarrativeFormCustomSectionList sectionName="affordabilitySections" />
            </NarrativeFormSection>
        </NarrativeForm>
    )
}
