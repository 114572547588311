import { gql, useQuery } from '@apollo/client'

export default function useQueryProjectById(projectId: number, skip = false) {
    // Note that returned entity won't be cached by Apollo because its key is not 'id'.
    return useQuery(
        gql`
            query projectById($projectId: Int!) {
                projectById(projectId: $projectId) {
                    id
                }
            }
        `,
        {
            skip,
            variables: {
                projectId,
            },
        },
    )
}
