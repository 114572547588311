import React from 'react'

type Props = {
    className?: string
    htmlFor: string
    label: string
    halfWidth?: boolean
    fullWidth?: boolean
    children?: React.ReactNode
}

export default function NarrativeFormField(props: Props) {
    const { className, htmlFor, label, fullWidth, halfWidth, children = null } = props

    return (
        <div className={`NarrativeFormField row  ${className || ''}`}>
            <div className={`col-form-label ${fullWidth ? 'col-12' : 'col-md-4'}`}>
                <label htmlFor={htmlFor}>{label}</label>
            </div>
            <div className={`${fullWidth ? 'col-12' : halfWidth ? 'col-md-4' : 'col-md-8'} ${className || ''}`}>
                <div className={'controlCol'}>{children}</div>
            </div>
        </div>
    )
}
